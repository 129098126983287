import React, { useCallback, useEffect } from 'react';
import { useTranslation } from '@shared/core';
import { TextV2, ButtonV2, useDisclosure, SpacingStack, DialogV2, Box, LinkV2 } from '@withjoy/joykit';
import { useExpressCheckoutContext } from '../../ExpressCheckout.provider';
import { pxToRem } from '@withjoy/joykit/theme';
import { useIntercom } from '@shared/core/intercom';

export const CheckoutErrorDialog: React.FC = () => {
  const { t2 } = useTranslation('guestRegistry');
  const trans = t2('expressCheckout', 'errorDialog');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    state: { checkoutError }
  } = useExpressCheckoutContext();
  useEffect(() => {
    if (checkoutError) {
      onOpen();
    }
  }, [checkoutError, onOpen]);
  const intercom = useIntercom();
  const openIntercom = useCallback(async () => {
    await intercom.boot();
    intercom.show();
  }, [intercom]);

  return (
    <DialogV2 isOpen={isOpen} onClose={onClose} enableDividers={true} size="lg">
      <DialogV2.Header>
        <TextV2 typographyVariant="hed4" textAlign={'center'} marginRight={pxToRem(40)}>
          {trans.header}
        </TextV2>
        <DialogV2.CloseButton style={{ top: '0.75rem' }} onClick={onClose} />
      </DialogV2.Header>
      <DialogV2.Body>
        <SpacingStack spacing={6}>
          <TextV2 typographyVariant={'body2'} textAlign={'justify'}>
            {checkoutError?.message ?? (
              <>
                {trans.defaultMessageBeginning}
                <LinkV2 typographyVariant={'body2'} onClick={openIntercom}>
                  {trans.defaultMessageContactSupport}
                </LinkV2>
                .
              </>
            )}
          </TextV2>
          {checkoutError?.code && (
            <Box>
              <TextV2 display="inline-block" fontWeight={'bold'} marginRight={'0.5rem'}>
                {trans.code}:
              </TextV2>
              <TextV2 display="inline-block">{checkoutError.code}</TextV2>
            </Box>
          )}
        </SpacingStack>
      </DialogV2.Body>
      <DialogV2.Footer>
        <ButtonV2 intent="neutral" onClick={onClose}>
          {trans.okay}
        </ButtonV2>
      </DialogV2.Footer>
    </DialogV2>
  );
};
