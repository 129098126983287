import React, { useState, useCallback, useEffect, useContext } from 'react';
import { CoupleHeader } from './components';
import { RegistryFooter } from './components/RegistryFooter';
import { GuestRegistryState } from '../../GuestRegistry.controller';
import '@assets/charm.css';
import { useNativeState } from '@shared/core/native';
import { NotificationToast } from '@apps/registry/common/components/NotificationToast';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { Helmet } from 'react-helmet-async';
import { StyledMainSection, styles } from './GuestRegistry.styles';
import { useGuestRegistryController } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { Box, Flex } from '@withjoy/joykit';
import { Shell } from '@apps/guest/packages/layout-engine/layouts/LayoutBrannan/components/Shell';
import { URLS } from '@shared/utils/urls';
import { GuestRegistryPreviewListener, OnPreviewListenerUpdate } from '../../GuestRegistryPreviewListener';
import { DesignLayoutType } from '@graphql/generated';
import { AddExternalOrderTrackingDialog } from '@apps/registry/common/components/AddExternalOrderTracking';
import { OrderTrackingContext } from '@apps/registry/guest/providers';
import { GuestShopping } from './components/GuestShopping';
import { GuestSiteStateProvider } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { StripeElements, stripeLazySetupOptions } from '@shared/core/stripe';
import { ShoppingCartTelemetryProvider } from '@apps/registry/common/components/ShoppingCart/ShoppingCart.telemetry';
import { ShoppingCartProvider } from '@apps/registry/common/components/ShoppingCart/state';
import { CartCheckoutSteps } from '@apps/registry/common/components/ShoppingCart';
import { getGiftWrapThemeListByEventType } from '@shared/components';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useToggleIntercomLauncher } from '@shared/core/intercom';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useColorPaletteProvider } from '@apps/guest';

interface ContentProps extends Readonly<{ eventHandle: string; registryState: GuestRegistryState; eventId: string; handleOnPreviewUpdate: OnPreviewListenerUpdate }> {}

export const GuestRegistryContent: React.FC<ContentProps> = ({ eventHandle, registryState, eventId, handleOnPreviewUpdate }) => {
  const { toast, openToast } = useGuestRegistryController();

  const { eventDate, isAdmin, pageNote, firebaseId, registryAdminPhoto, primaryTextColor, font, pages, layout } = registryState;

  const [registryPhoto, setRegistryPhoto] = useState(registryAdminPhoto);

  useEffect(() => {
    setRegistryPhoto(registryAdminPhoto);
  }, [registryAdminPhoto]);

  const { isNative } = useNativeState();
  const [isToast, setIsToast] = useState<boolean>(false);
  const [isShowCartSection, setIsShowCartSection] = useState<boolean>(false);
  const { backgroundColor } = useColorPaletteProvider();
  const customMainSectionStyles = backgroundColor ? { marginX: 0 } : {};

  const { t } = useTranslation('sharedRegistry');
  const { itemAddedSuccess } = t('shoppingCart');

  const showToast = useCallback(() => {
    setIsShowCartSection(true);
    setIsToast(true);
    withWindow(global => global.setTimeout(() => setIsToast(false), 3000), 0);
    withWindow(global => global.setTimeout(() => setIsShowCartSection(false), 10000), 0);
  }, []);

  const { isAddExternalOrderTrackingDialogOpen, closeAddExternalOrderTrackingDialog, orderForTrackingRequest } = useContext(OrderTrackingContext);

  if (!eventId) {
    return null;
  }

  return (
    <GuestSiteStateProvider eventHandle={eventHandle}>
      <GuestRegistryPreviewListener eventHandle={eventHandle} setRegistryPhoto={setRegistryPhoto} pages={[...(pages || [])]} onUpdate={handleOnPreviewUpdate} />
      <NotificationToast {...toast} />
      <Helmet>
        <script src={URLS.styleApplicatorLib} />
      </Helmet>
      <NotificationToast isPositionTop={false} notificationText={itemAddedSuccess()} notificationVisible={isToast} setNotificationVisible={setIsToast} />
      <Shell eventDesign={registryState.eventDesign} noTranslucency isAlohaGuestPage={layout === DesignLayoutType.aloha}>
        <CoupleHeader
          eventDate={eventDate}
          isAdmin={isAdmin}
          pageNote={pageNote}
          website={eventHandle}
          firebaseId={firebaseId}
          eventId={eventId}
          isNative={isNative}
          registryPhoto={registryPhoto}
          font={font}
          pages={pages}
          layout={layout}
          primaryTextColor={primaryTextColor}
        />
        <Flex __css={styles.wrapperNewMvp}>
          <StyledMainSection __css={{ ...styles.mainSection, ...customMainSectionStyles }}>
            {backgroundColor ? (
              <Box backgroundColor="white" paddingTop={7} paddingBottom={10} paddingX={10}>
                <GuestShopping eventHandle={eventHandle} eventId={eventId} registryState={registryState} showToast={showToast} isShowCartSection={isShowCartSection} />
              </Box>
            ) : (
              <GuestShopping eventHandle={eventHandle} eventId={eventId} registryState={registryState} showToast={showToast} isShowCartSection={isShowCartSection} />
            )}
            <RegistryFooter />
          </StyledMainSection>
          <AddExternalOrderTrackingDialog
            openToast={openToast}
            onClose={closeAddExternalOrderTrackingDialog}
            isOpen={isAddExternalOrderTrackingDialogOpen}
            orderId={orderForTrackingRequest?.id || ''}
            orderQuantity={orderForTrackingRequest?.lineItems[0]?.quantity.toString() || '1'}
            orderTitle={orderForTrackingRequest?.lineItems[0]?.frozenProductData.title}
            orderPhotoUrl={orderForTrackingRequest?.lineItems[0]?.frozenProductData.photos[0]?.url}
            orderNumber={orderForTrackingRequest?.fulfillment?.orderNumber}
            trackingNumber={orderForTrackingRequest?.fulfillment?.shipments?.[0]?.trackingInformation?.trackingNumber}
            email={orderForTrackingRequest?.email || ''}
            isPurchased={orderForTrackingRequest?.isPurchased || false}
          />
        </Flex>
      </Shell>
    </GuestSiteStateProvider>
  );
};

interface Props extends Readonly<{ eventHandle: string; registryState: GuestRegistryState; eventId: string; handleOnPreviewUpdate: OnPreviewListenerUpdate }> {}

export const GuestRegistry: React.FC<Props> = ({ eventHandle, registryState, eventId, handleOnPreviewUpdate }) => {
  useToggleIntercomLauncher({ show: false, minScreenSize: 'mobile' });
  const { eventInfo } = useEventInfo();
  const themes = getGiftWrapThemeListByEventType(eventInfo?.eventType);
  const eventTypeHasThemes = themes && themes.length > 0;
  const { value: giftWrapOn } = useFeatureValue('registryGiftWrapExperimentEnabled');
  const registryGiftWrapExperimentEnabled = giftWrapOn === 'on';
  const { value: gwRegCartEnabled } = useFeatureValue('registryGiftWrapCartEnabled');
  const registryGiftWrapCartEnabled = gwRegCartEnabled === 'on';
  // Verify the registry item is gift wrap experiment is enabled, and gift wrap for cart is enabled
  const isGiftWrapEnabled = registryGiftWrapExperimentEnabled && registryGiftWrapCartEnabled && eventTypeHasThemes;
  useToggleIntercomLauncher({ show: true, minScreenSize: 'mobile' });

  const checkoutStepsFlow = [
    CartCheckoutSteps.Cart,
    CartCheckoutSteps.Shipping,
    CartCheckoutSteps.Details,
    ...(isGiftWrapEnabled ? [CartCheckoutSteps.Message] : []),
    CartCheckoutSteps.Payment
  ];
  return (
    <ShoppingCartProvider coupleAddress={registryState.shippingAddress} eventHandle={eventHandle} eventId={eventId} checkoutStepsFlow={checkoutStepsFlow}>
      <ShoppingCartTelemetryProvider context={{ eventId: registryState.eventId }}>
        <StripeElements options={stripeLazySetupOptions}>
          <GuestRegistryContent eventHandle={eventHandle} eventId={eventId} registryState={registryState} handleOnPreviewUpdate={handleOnPreviewUpdate} />
        </StripeElements>
      </ShoppingCartTelemetryProvider>
    </ShoppingCartProvider>
  );
};
