import { useMemo, useCallback, useState, useEffect } from 'react';
import { calculateNumberPrice, isPricedProduct, isCustomDonationFundItem, isCustomCharityFundItem } from '@apps/registry/common/selectors/ProductListSelector';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import {
  useDeleteRegistryOrderMutation,
  useReserveRegistryOrderMutation,
  usePurchaseRegistryOrderMutation,
  useGetItemCheckoutMechanismsQuery,
  DonationFundPaymentMethodEnum
} from '@graphql/generated';
import { isValidUrl } from '@shared/utils/urlHelpers';
import { getPaymentMethodTypeName, getPaymentMethodFormattedName } from '@shared/utils/paymentMethodOptions';
import { ReservedItemControllerProps, RemoveActionProps } from './ShoppingCart.types';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { removeItemFromPurchaseClickHistory } from './utils/trackPurchaseClicks';
import { useToast } from '@withjoy/joykit';
import { TranslateFunction } from '@locales/generated';
import { useShoppingCartTelemetry } from './ShoppingCart.telemetry';
import { deriveCheckoutMechanismResults } from '../../utils/checkoutMechanisms';

export interface ReserveItemFields {
  orderId: string;
  shippingCarrier: string;
  tacking: string;
}

export const useReservedItemController = ({ product, order, itemQuantity, orderId, eventId, isDropship, lineItem }: ReservedItemControllerProps) => {
  const { toast: addToast } = useToast();

  const isDonationFundItem = !!(isCustomDonationFundItem(product) || (lineItem?.lineItemData.donationFundPlatformType && product?.isGroupGiftingEnabled));
  const isCharityFundItem = isCustomCharityFundItem(product);
  const { t } = useTranslation('sharedRegistry');
  const { donationNow } = t('shoppingCart');
  const { formatCurrency } = useCurrencyFormatter();

  const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
  const [toast, setToast] = useState<{ text: string; showConfettiAnimation: boolean }>({ text: '', showConfettiAnimation: false });
  const [toastVisible, setToastVisibility] = useState<boolean>(false);
  const [isOutOfStock, setIsOutOfStock] = useState<boolean>(false);
  const shoppingCartTran = t('shoppingCart');
  const [removeItemReason, setRemoveItemReason] = useState<keyof typeof shoppingCartTran | undefined>();

  const [deleteRegistryOrder] = useDeleteRegistryOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });
  const [reserveRegistryOrder] = useReserveRegistryOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });
  const [purchaseRegistryOrder] = usePurchaseRegistryOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });

  const donationFundOrder = useMemo(() => {
    return product && product.donationFund?.fund.donations.find(donation => donation.id === orderId);
  }, [product, orderId]);

  const { data } = useGetItemCheckoutMechanismsQuery({
    batchMode: 'fast',
    variables: { id: product?.id as string }
  });

  const { isOutOfStock: serverSaysOutOfStock } = deriveCheckoutMechanismResults(data?.registryItem);

  useEffect(() => {
    if (serverSaysOutOfStock) {
      setIsOutOfStock(true);
    }
  }, [serverSaysOutOfStock, product?.id]);

  const getDonationFundTitle = () => {
    if (order && !isCharityFundItem && order?.lineItems[0]?.lineItemData?.donationFundPlatformType !== DonationFundPaymentMethodEnum.other) {
      const platformDisplayName = getPaymentMethodTypeName(order.lineItems[0]?.lineItemData?.donationFundPlatformType!);
      return platformDisplayName ? shoppingCartTran.donationSendPlatform({ type: platformDisplayName }) : shoppingCartTran.donationSendPayment();
    }
    return donationNow();
  };

  const telemetry = useShoppingCartTelemetry();
  const orderPrice = useMemo(() => {
    let price = '';
    if (product) {
      if (product.donationFund) {
        const { donations } = product.donationFund.fund;
        const donation = donations.find(donation => donation.id === orderId);
        if (donation) {
          price = formatCurrency({
            priceFloatingPointDecimalString: `${calculateNumberPrice(donation.amount)}`,
            priceCurrencyCode: product.donationFund?.fund.currency.code,
            formatForm: 'short'
          });
        }
      } else {
        if (isPricedProduct(product)) {
          if (!!lineItem?.frozenProductData?.price && isDropship) {
            const frozenPrice = calculateNumberPrice(lineItem.frozenProductData.price);
            price = formatCurrency({ priceFloatingPointDecimalString: `${frozenPrice * itemQuantity}`, priceCurrencyCode: product.currencyCode, formatForm: 'short' });
          } else {
            price = formatCurrency({ priceFloatingPointDecimalString: `${product.numberPrice * itemQuantity}`, priceCurrencyCode: product.currencyCode, formatForm: 'short' });
          }
        }
      }
    }
    return price;
  }, [product, itemQuantity, orderId, isDropship, lineItem, formatCurrency]);

  const priceSpanMarkup = useMemo(() => {
    let price = '';
    if (product) {
      if (product.donationFund) {
        const { donations } = product.donationFund.fund;
        const donation = donations.find(donation => donation.id === orderId);
        if (donation) {
          price = formatCurrency({
            priceFloatingPointDecimalString: `${calculateNumberPrice(donation.amount)}`,
            priceCurrencyCode: donation.amount.currency?.code,
            formatForm: 'short'
          });
        }
      } else {
        if (isPricedProduct(product)) {
          price = product.price;
        }
      }
    }

    return price;
  }, [product, orderId, formatCurrency]);

  const showToast = useCallback((text: string, showConfettiAnimation: boolean) => {
    setToastVisibility(true);
    setToast({ text, showConfettiAnimation });
    withWindow(global => global.setTimeout(() => setToastVisibility(false), 800000), 0);
  }, []);

  const siteName = useMemo(() => {
    if (!isValidUrl(product?.externalUrl)) {
      return product?.externalUrl;
    }
    const domain = new URL(product?.externalUrl || '');
    const siteName = domain.hostname.replace('www.', '');
    if (!isDonationFundItem) {
      return siteName.charAt(0).toUpperCase() + siteName.slice(1);
    }
    const type = donationFundOrder?.platformType || '';
    return getPaymentMethodFormattedName(type);
  }, [product, donationFundOrder, isDonationFundItem]);

  const handleClickPurchase = useCallback(() => {
    const telemArgs = {
      eventId,
      productId: product?.id,
      registryItemId: product?.registryItemId,
      registryId: product?.registry.id,
      reservedQty: itemQuantity,
      priceValueInMinorUnits: isPricedProduct(product) ? product.valueInMinorUnits : undefined,
      priceCurrencyCode: isPricedProduct(product) ? product.currencyCode : undefined,
      destinationUrl: product?.checkoutUrl || product?.registry.url,
      typeOfItem: product?.registryItemType,
      buttonLabel: shoppingCartTran.continuePurchase(),
      productTitle: product?.title,
      giftGiverName: order?.name,
      giftGiverEmail: order?.email,
      isGroupGiftingEnabled: !!product?.isGroupGiftingEnabled
    };
    telemetry.ShoppingCartPurchaseClick(telemArgs);
  }, [telemetry, eventId, product, itemQuantity, shoppingCartTran, order]);

  const handleClickMarkPurchase = useCallback(async () => {
    const telemArgs = {
      eventId,
      productId: product?.id,
      registryItemId: product?.registryItemId,
      registryId: product?.registry.id,
      reservedQty: isDonationFundItem ? 1 : itemQuantity,
      priceValueInMinorUnits: isDonationFundItem ? itemQuantity : isPricedProduct(product) ? product.valueInMinorUnits : undefined,
      priceCurrencyCode: isPricedProduct(product) ? product.currencyCode : undefined,
      destinationUrl: product?.checkoutUrl || product?.registry.url,
      typeOfItem: product?.registryItemType,
      buttonLabel: 'Shopping Cart',
      productTitle: product?.title,
      giftGiverName: order?.name,
      giftGiverEmail: order?.email,
      isGroupGiftingEnabled: !!product?.isGroupGiftingEnabled
    };
    telemetry.shoppingCartMakePurchaseClick(telemArgs);
    try {
      const result = await purchaseRegistryOrder({
        variables: {
          id: orderId
        }
      });
      if (product?.id) {
        removeItemFromPurchaseClickHistory({ reservedOrderId: orderId, productId: product.id });
      }
      if (result) {
        showToast(shoppingCartTran.purchaseSuccess(), true);
      }
    } catch (error) {
      addToast(shoppingCartTran.errorMessage());
    }
  }, [addToast, orderId, showToast, purchaseRegistryOrder, telemetry, eventId, product, itemQuantity, shoppingCartTran, order, isDonationFundItem]);

  const handleClickViewDetails = useCallback(() => {
    const purchasePath = window.location.pathname + '/purchase?pcId=' + orderId;
    withWindow(window => {
      window.open(purchasePath, '__blank');
    });
  }, [orderId]);

  const handleClickOrderButton = () => {
    if (isDropship) {
      handleClickViewDetails();
    } else {
      handleClickDidntPurchase();
    }
  };

  const handleClickCancel = useCallback(() => {
    setShowCancelConfirmation(!showCancelConfirmation);
    const telemArgs = {
      eventId,
      productId: product?.id,
      registryItemId: product?.registryItemId,
      registryId: product?.registry.id,
      reservedQty: itemQuantity,
      priceValueInMinorUnits: isPricedProduct(product) ? product.valueInMinorUnits : undefined,
      priceCurrencyCode: isPricedProduct(product) ? product.currencyCode : undefined,
      destinationUrl: product?.checkoutUrl || product?.registry.url,
      typeOfItem: product?.registryItemType,
      buttonLabel: shoppingCartTran.cancelReservation(),
      productTitle: product?.title,
      giftGiverName: order?.name,
      giftGiverEmail: order?.email
    };
    telemetry.ShoppingCartCancelReservationClick(telemArgs);
  }, [eventId, showCancelConfirmation, product, itemQuantity, shoppingCartTran, telemetry, order]);

  const handleRemoveClick = useCallback(async () => {
    const removeReasonTranslation =
      removeItemReason && ['changed', 'looking', 'available'].includes(removeItemReason) ? (shoppingCartTran[removeItemReason] as TranslateFunction) : undefined;
    const removeReason = removeReasonTranslation?.() || '';

    const telemArgs = {
      eventId,
      productId: product?.id,
      registryItemId: product?.registryItemId,
      registryId: product?.registry.id,
      reservedQty: itemQuantity,
      priceValueInMinorUnits: isPricedProduct(product) ? product.valueInMinorUnits : undefined,
      priceCurrencyCode: isPricedProduct(product) ? product.currencyCode : undefined,
      destinationUrl: product?.checkoutUrl || product?.registry.url,
      typeOfItem: product?.registryItemType,
      buttonLabel: shoppingCartTran.cancelReservation(),
      productTitle: removeReason,
      isGroupGiftingEnabled: !!product?.isGroupGiftingEnabled
    };
    telemetry.ShoppingCartRemoveItemClick(telemArgs);
    try {
      const result = await deleteRegistryOrder({
        variables: {
          id: orderId
        }
      });
      if (product?.id) {
        removeItemFromPurchaseClickHistory({ reservedOrderId: orderId, productId: product.id });
      }
      if (result) {
        showToast(shoppingCartTran.removeSuccess(), false);
      }
    } catch (error) {
      addToast(shoppingCartTran.errorMessage());
    }
  }, [orderId, addToast, telemetry, showToast, removeItemReason, eventId, product, itemQuantity, shoppingCartTran, deleteRegistryOrder]);

  const handleClickDidntPurchase = useCallback(async () => {
    const telemArgs = {
      eventId,
      productId: product?.id,
      registryItemId: product?.registryItemId,
      registryId: product?.registry.id,
      reservedQty: itemQuantity,
      priceValueInMinorUnits: isPricedProduct(product) ? product.valueInMinorUnits : undefined,
      priceCurrencyCode: isPricedProduct(product) ? product.currencyCode : undefined,
      destinationUrl: product?.checkoutUrl || product?.registry.url,
      typeOfItem: product?.registryItemType,
      buttonLabel: shoppingCartTran.didntPurchase(),
      productTitle: product?.title,
      giftGiverName: order?.name,
      giftGiverEmail: order?.email
    };
    telemetry.ShoppingCartDidntPurchaseClick(telemArgs);
    if (product?.checkoutUrl && !isOutOfStock) {
      try {
        const result = await reserveRegistryOrder({
          variables: {
            id: orderId
          }
        });
        if (result) {
          showToast(shoppingCartTran.reservedSuccess(), false);
        }
      } catch (error) {
        addToast(shoppingCartTran.errorMessage());
      }
    } else {
      handleRemoveClick();
    }
  }, [reserveRegistryOrder, showToast, addToast, orderId, telemetry, eventId, product, itemQuantity, shoppingCartTran, handleRemoveClick, isOutOfStock, order]);

  const getShoppingCartProps = (): RemoveActionProps => {
    return {
      removeAction: [
        {
          label: shoppingCartTran.looking(),
          value: 'looking',
          onChange: () => {
            setRemoveItemReason('looking');
          }
        },
        {
          label: shoppingCartTran.available(),
          value: 'available',
          onChange: () => {
            setRemoveItemReason('available');
          }
        },
        {
          label: shoppingCartTran.changed(),
          value: 'changed',
          onChange: () => {
            setRemoveItemReason('changed');
          }
        }
      ]
    };
  };

  const formatCurrencyWrapper = useCallback(
    (priceFloatingPointDecimalString: string, priceCurrencyCode?: string) =>
      formatCurrency({ priceFloatingPointDecimalString, priceCurrencyCode, formatForm: 'short', shouldCalculateCharmPrice: true }),
    [formatCurrency]
  );

  const totalPrice = useMemo(() => {
    let price = '';
    if (product) {
      if (product.donationFund) {
        const { donations } = product.donationFund.fund;
        const donation = donations.find(donation => donation.id === orderId);
        if (donation) {
          price = formatCurrencyWrapper(`${calculateNumberPrice(donation.amount)}`, product.donationFund?.fund.currency.code);
        }
      } else {
        if (isPricedProduct(product)) {
          if (!!lineItem?.frozenProductData?.price && isDropship) {
            const frozenPrice = calculateNumberPrice(lineItem.frozenProductData.price);
            price = formatCurrencyWrapper(`${frozenPrice * itemQuantity}`, product.currencyCode);
          } else {
            price = formatCurrencyWrapper(`${product.numberPrice * itemQuantity}`, product.currencyCode);
          }
        }
      }
    }
    return price;
  }, [product, itemQuantity, orderId, isDropship, lineItem, formatCurrencyWrapper]);

  const price = useMemo(() => {
    let price = '';
    if (product) {
      if (product.donationFund) {
        const { donations } = product.donationFund.fund;
        const donation = donations.find(donation => donation.id === orderId);
        if (donation) {
          price = formatCurrencyWrapper(`${calculateNumberPrice(donation.amount)}`, donation.amount.currency?.code);
        }
      } else {
        if (isPricedProduct(product)) {
          if (!!lineItem?.frozenProductData?.price && isDropship) {
            const frozenPrice = calculateNumberPrice(lineItem.frozenProductData.price);
            price = formatCurrencyWrapper(`${frozenPrice}`, product.currencyCode);
          } else {
            price = formatCurrencyWrapper(`${product.numberPrice}`, product.currencyCode);
          }
        }
      }
    }

    return price;
  }, [product, orderId, formatCurrencyWrapper, isDropship, lineItem]);

  return {
    siteName,
    priceSpanMarkup,
    orderPrice,
    handleClickCancel,
    handleClickPurchase,
    handleClickMarkPurchase,
    isDonationFundItem,
    donationFundTitle: getDonationFundTitle(),
    handleRemoveClick,
    shoppingCartProp: getShoppingCartProps(),
    removeItemReason,
    showCancelConfirmation,
    toastVisible,
    toast,
    setToastVisibility,
    handleClickOrderButton,
    price,
    totalPrice
  };
};
