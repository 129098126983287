import React, { useMemo, useRef } from 'react';
import { GuestRegistryState } from '@apps/registry/guest/GuestRegistry.controller';
import { Box, Divider, Flex, SpacingStack } from '@withjoy/joykit';
import { breakpointAliasPx, pxToRem } from '@withjoy/joykit/theme';
import GiftWrapOffer from '@apps/registry/guest/components/CheckoutDialog/components/GiftWrapOffer';
import { ExpressCheckoutProvider, useExpressCheckoutContext } from './ExpressCheckout.provider';
import { Skeleton, SkeletonText, SkeletonThumbnail } from '@shared/components/Skeleton';
import { ShippingSettings } from './components/ShippingSettings';
import { StripeElements, stripeLazySetupOptions } from '@shared/core/stripe';
import { PaymentSummary } from './components/PaymentSummary';
import { JoyLogo } from '@assets/index';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { withWindow } from '@shared/utils/withWindow';
import { ExpressCheckoutButton } from './components/ExpressCheckoutButton';
import { CheckoutErrorDialog } from './components/CheckoutErrorDialog';
import { useMount } from '@shared/utils/hooks/useMount';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useGetEventRegistriesAndOrdersQuery } from '@graphql/generated';
import { useCookedProductList } from '../../selectors/ProductListSelector';

export interface ExpressCheckoutPurchaseItem {
  registryItemId: string;
  quantity?: number;
}

interface ExpressCheckoutProps {
  eventHandle: string;
  eventId: string;
  purchaseItems: ExpressCheckoutPurchaseItem[];
  registryState: GuestRegistryState;
}

export const Root = () => {
  const {
    state: { purchaseProducts },
    mutators: { setGiftWrap }
  } = useExpressCheckoutContext();
  const windowWidth = withWindow(global => global.innerWidth, 800);
  const [isMobile] = useResponsive({ values: { xxs: true, sm3: false } }, windowWidth < breakpointAliasPx.sm3);
  const giftWrapProduct = useMemo(
    () =>
      purchaseProducts.length
        ? {
            title: purchaseProducts[0].title,
            image: purchaseProducts[0].image,
            store: purchaseProducts[0].storeName ?? purchaseProducts[0].brand ?? undefined,
            extraProductsCount: purchaseProducts.length - 1
          }
        : null,
    [purchaseProducts]
  );

  return (
    <>
      <CheckoutErrorDialog />
      <ExpressCheckoutLayout
        settings={
          <>
            <ShippingSettings />
            <GiftWrapOffer giverName={''} giftName={giftWrapProduct?.title ?? ''} product={giftWrapProduct} onChange={setGiftWrap} />
          </>
        }
        summary={
          <>
            <PaymentSummary />
            {!isMobile && <ExpressCheckoutButton />}
          </>
        }
      />
      {isMobile && (
        <SpacingStack
          spacing={6}
          position={'fixed'}
          bottom={0}
          padding={6}
          backgroundColor={'white'}
          width={'100%'}
          borderRadius={'12px 12px 0px 0px'}
          boxShadow={'0px 10px 60px -20px rgba(45, 41, 37, 0.12), 0px 9px 36px -30px rgba(0, 0, 0, 0.14)'}
        >
          <ExpressCheckoutButton />
        </SpacingStack>
      )}
    </>
  );
};

export const ExpressCheckoutLayout = (props: { settings: React.ReactNode; summary: React.ReactNode }) => {
  const settingsRef = useRef<HTMLDivElement>(null);
  useMount(() => {
    settingsRef.current?.scrollIntoView();
  });
  return (
    <Flex flexDirection={{ _: 'column', sm3: 'row' }} height={'100x%'} alignItems={{ _: 'center', sm3: 'stretch' }} paddingBottom={{ _: pxToRem(220), sm3: pxToRem(390) }}>
      <Flex flexDirection={'column'} width={{ _: '100%', sm3: '58.4%' }} alignItems={'center'} paddingX={'1rem'} paddingY={{ _: pxToRem(56), sm3: pxToRem(80) }}>
        <JoyLogo height={pxToRem(38)} />
        <SpacingStack spacing={6} width={'100%'} maxWidth={pxToRem(475)} marginTop={pxToRem(48)} ref={settingsRef}>
          {props.settings}
        </SpacingStack>
      </Flex>
      <Flex
        flexDirection={'column'}
        backgroundColor={'mono1'}
        alignItems={'center'}
        width={{ _: '100%', sm3: '41.6%' }}
        paddingX={'1rem'}
        paddingY={{ _: pxToRem(56), sm3: pxToRem(80) }}
      >
        <Flex flexDirection={'column'} maxWidth={pxToRem(320)} width={'100%'} position={{ _: 'static', sm3: 'fixed' }}>
          <SpacingStack spacing={6}>{props.summary}</SpacingStack>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const ExpressCheckoutSkeleton = () => {
  return (
    <ExpressCheckoutLayout
      settings={
        <>
          <Box width={'100%'}>
            <Skeleton width={'30%'} height={'1rem'} marginBottom={2} />
            <Skeleton width={'100%'} height={pxToRem(60)} />
          </Box>
          <Box width={'100%'}>
            <Skeleton width={'30%'} height={'1rem'} marginBottom={2} />
            <Skeleton width={'100%'} height={pxToRem(84)} marginBottom={2} />
            <Skeleton width={'100%'} height={pxToRem(84)} />
          </Box>
          <Skeleton width={'100%'} height={pxToRem(1000)} />
        </>
      }
      summary={
        <>
          <Flex>
            <SkeletonThumbnail height={pxToRem(96)} width={pxToRem(96)} marginRight={2} />
            <SkeletonText height={pxToRem(96)} flex={1} rows={4} skeletonHeight={'0.75rem'} />
          </Flex>
          <Divider marginY={4} />
          <SkeletonText width={'100%'} rows={3} skeletonHeight={'1rem'} />
          <Divider marginY={4} />
          <Skeleton width={'100%'} height={pxToRem(28)} />
          <Skeleton height={pxToRem(50)} width={'100%'} borderRadius={pxToRem(25)} />
        </>
      }
    />
  );
};

export const ExpressCheckout = (props: ExpressCheckoutProps) => {
  const { orders, updateOrdersAndRegistry, registry: registries } = useGuestRegistryState();
  const allProducts = useCookedProductList(registries, orders, 'everything');
  useGetEventRegistriesAndOrdersQuery({
    batchMode: 'fast',
    variables: { eventHandle: props.eventHandle },
    onCompleted: data => {
      const orders = data?.eventByName?.registry.orders;
      const registry = data?.eventByName?.info.registry;
      if (orders || registry) {
        updateOrdersAndRegistry(orders, registry);
      }
    }
  });

  return (
    <StripeElements options={stripeLazySetupOptions}>
      {allProducts.products.length ? (
        <ExpressCheckoutProvider {...props} allProducts={allProducts}>
          <Root />
        </ExpressCheckoutProvider>
      ) : (
        <ExpressCheckoutSkeleton />
      )}
    </StripeElements>
  );
};
