import { styled, Flex, LinkV1, Divider } from '@withjoy/joykit';

export const StyledLink = styled(LinkV1)<{ isTertiary?: boolean | false }>`
  min-height: 48px;
  min-width: 132px;
  ${({ theme, isTertiary }) =>
    isTertiary
      ? `
          color: ${theme.colors.negative6};
          &:hover {
            color: ${theme.colors.negative5} !important;
          }
          &:active {
            color: ${theme.colors.negative7}  !important;
          }
        `
      : undefined};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const StyledDivider = styled(Divider)`
  right: 0;
  left: 0;
`;

export const StyledDialogFooter = styled(Flex)<{ isMobile?: boolean | false }>`
  ${props =>
    props.isMobile
      ? `
          bottom: 0;
          width: 100%;
          flex-direction: column-reverse;
          justify-content: center;
          height: unset;`
      : undefined};
`;

export const StyledFlex = styled(Flex)<{ isMobile?: boolean | false }>`
  ${props =>
    props.isMobile
      ? `flex-direction: column-reverse;
          width: 100%;
        `
      : undefined};
`;
