import { Skeleton } from '@shared/components/Skeleton';
import { ExpressCheckoutElement, ExpressCheckoutElementProps } from '@stripe/react-stripe-js';
import { AvailablePaymentMethods, StripeExpressCheckoutElementReadyEvent } from '@stripe/stripe-js';
import { Box } from '@withjoy/joykit';
import React, { useCallback, useMemo, useState } from 'react';

interface ExpressCheckoutElementParentClickEvent {
  availablePaymentMethods?: AvailablePaymentMethods | null;
}

type LoadingExpressCheckoutElementProps = ExpressCheckoutElementProps & {
  // Use when you want to postpone loading the element (e.g. until a feature flag is ready)
  waitToLoad?: boolean;
  onParentClick?: (event: ExpressCheckoutElementParentClickEvent) => void;
  disablePaymentEvents?: boolean;
  showFallback?: boolean;
};

const DISABLED_ALL_PAYMENT_METHODS = { amazonPay: false, applePay: false, googlePay: false, link: false, paypal: false };

export const LoadingExpressCheckoutElement: React.FC<LoadingExpressCheckoutElementProps> = props => {
  const [loading, setLoading] = useState(true);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<AvailablePaymentMethods | null>(null);
  const handleReady = useCallback(
    (event: StripeExpressCheckoutElementReadyEvent) => {
      setLoading(false);
      setAvailablePaymentMethods(event.availablePaymentMethods ?? DISABLED_ALL_PAYMENT_METHODS);
      props.onReady?.(event);
    },
    [setLoading, setAvailablePaymentMethods, props]
  );
  const handleLoadError = useCallback(
    event => {
      setLoading(false);
      setAvailablePaymentMethods(DISABLED_ALL_PAYMENT_METHODS);
      props.onLoadError?.(event);
    },
    [setLoading, setAvailablePaymentMethods, props]
  );

  // show fallback when no payment methods are available
  const showFallback = useMemo(() => props.showFallback || (availablePaymentMethods && Object.values(availablePaymentMethods).every(value => value === false)), [
    availablePaymentMethods,
    props.showFallback
  ]);
  const loadingButton = <Skeleton height={`${props.options?.buttonHeight ?? 42}px`} width={'100%'} borderRadius={'25px'} />;

  if (props.waitToLoad) {
    return loadingButton;
  }

  return (
    <>
      {showFallback ? (
        props.children
      ) : (
        <>
          {loading && loadingButton}
          <Box onClick={() => props.onParentClick?.({ availablePaymentMethods })} _hover={{ cursor: props.onParentClick ? 'pointer' : null }} position={'relative'}>
            {props.disablePaymentEvents && <Box width={'100%'} height={'100%'} position={'absolute'} zIndex={1} _hover={{ background: 'rgba(255,255,255,0.3)' }} />}
            <Box pointerEvents={props.disablePaymentEvents ? 'none' : null}>
              <ExpressCheckoutElement {...props} onReady={handleReady} onLoadError={handleLoadError} />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
