import React, { useState, useEffect } from 'react';
import { isPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { InputV2, TextArea, Box, Flex, SelectV1, TextV2, LinkV2, OptionType, ButtonV2 } from '@withjoy/joykit';
import { LaunchEmailSupportLink } from '@apps/registry/guest/components';
import { PreviewIcon, ChatIcon } from '@assets/icons';
import { MsrpTooltip } from '@apps/registry/common/components/MsrpTooltip';
import { PurchaseDialogProps } from '../../PurchaseDialog';
import { CheckoutMechanisms, FormStep, usePurchaseDialogController } from '../../PurchaseDialog.controller';
import {
  StyledProductLink,
  StyledDescription,
  StyledHowManyRequestLabel,
  StyledNeededLabel,
  StyledLabelForm,
  StyledInputError,
  StyledPurchasableMessage,
  StyledProductTitle,
  StyledProductPrice
} from './AffiliateProductDialogBody.styles';
import { LayoutShell } from '../LayoutShell';
import { getStoreLogoByReadableStoreName, getStoreName } from '@apps/registry/common/util/storeHelpers';
import { usePurchaseDialogTranslations } from '../../hooks';
import { MinimalCatalogProductItemDetails } from '@apps/registry/common/components/Catalog/Catalog.types';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import MustHaveChip from '@apps/registry/guest/components/CheckoutDialog/components/MustHaveChip';
import { FormattedCurrency } from '@shared/utils/currency';
import { useTranslation } from '@shared/core';
import { DROPDOWN_QUANTITY_LIMIT } from '../DropshipProductDialogBody/components/ProductInformation/ProductInformation';
import { ItemCounter } from '../../../../../../../common/components/ShoppingCart/ShoppingCartInnerV2/steps/CartReviewStep/components/InCartSection/components/InCartItem/ItemCounter';

type PurchaseDialogBodyProps = Merge<Omit<PurchaseDialogProps, 'isDialogOpen' | 'shippingAddress'>, Required<Pick<PurchaseDialogProps, 'product'>>> &
  CheckoutMechanisms & {
    pdpProduct?: Maybe<MinimalCatalogProductItemDetails>;
    purchaseErrorContent: string | null;
    formStep: FormStep;
    setFormStep: (formStep: FormStep) => void;
  };

// TODO: once split dialog is released remove isDropshipable logic
export const AffiliateProductDialogBody: React.FC<PurchaseDialogBodyProps> = ({
  closeDialog,
  product,
  eventId,
  eventInfoState,
  openToast,
  mySessionEmail,
  isDropshipable,
  isOutOfStock,
  pdpProduct,
  purchaseErrorContent,
  checkoutMechanismsWithAltAddress,
  formStep,
  setFormStep,
  msrpTranslations
}) => {
  const [isDescriptionShowed, setIsDescriptionShowed] = useState<boolean>(false);
  const { alreadyReservedGiftViewed } = useGuestRegistryTelemetry();
  const {
    formik,
    isPurchasable,
    createOrderLoading,
    handleViewItemDetails,
    handleOnChangeEmail,
    showDetailsLink,
    recentGiftStatus,
    productImageSrc,
    handleOnAffiliateMarkAsPurchasedClick,
    handleOnAffiliatePrimaryCTAClick,
    preparingForDropshipPurchaseLoading,
    updateRegistryItemLoading
  } = usePurchaseDialogController({
    closeDialog,
    product,
    itemId: product?.registryItemId || '',
    eventId,
    eventInfoState,
    openToast,
    shouldRequireName: true,
    shouldRequireEmail: !mySessionEmail,
    isDropshipable,
    isOutOfStock,
    checkoutMechanismsWithAltAddress,
    formStep,
    setFormStep
  });

  const {
    howManyRequest,
    fieldNameLabel,
    fieldEmailLabel,
    fieldNamePlaceholder,
    fieldEmailPlaceholder,
    fieldNoteLabel,
    fieldNotePlaceholder,
    formButtonMarkAsPurchased,
    hideDescription,
    readMore,
    outOfStock,
    descriptionLabel,
    detailsLinkText,
    questionV2LinkText,
    changeEmail,
    needed,
    fulfilledByJoy,
    fulfilledByExternal,
    alreadyReservedTitle,
    alreadyReservedSubtitle,
    alreadyReservedCheckEmail,
    formButtonBuyNow
  } = usePurchaseDialogTranslations();

  const handleOnReadMoreClick = () => {
    setIsDescriptionShowed(!isDescriptionShowed);
  };
  const { t: translations } = useTranslation('catalogRegistry');
  const { message } = translations('salesPrice');

  const hasToShowMsrp = product?.id && !product?.externallyOwned && !product?.canEditProductData;
  const storeName = product?.storeName || getStoreName(product?.externalUrl);
  const storeLogo = getStoreLogoByReadableStoreName(storeName);
  const stillNeededOptions: OptionType[] =
    product && product.stillNeeded >= 1 && product?.stillNeeded < DROPDOWN_QUANTITY_LIMIT
      ? Array.from(Array(product.stillNeeded).keys()).map(i => ({ label: `${i + 1}`, value: `${i + 1}` }))
      : [];

  useEffect(() => {
    if (recentGiftStatus?.alreadyReserved) {
      alreadyReservedGiftViewed({ label: recentGiftStatus.usedEmail });
    }
  }, [alreadyReservedGiftViewed, recentGiftStatus?.alreadyReserved, recentGiftStatus?.usedEmail]);

  return (
    <LayoutShell paddingTop={1}>
      <LayoutShell.MediaPanel fullBleedBackground={false} productImageSrc={productImageSrc} pdpProduct={pdpProduct}>
        <Box display="grid" gridAutoFlow="row" width="100%">
          {showDetailsLink && (
            <StyledProductLink target="_blank" typographyVariant="body1" color="mono10" onClick={handleViewItemDetails} marginBottom="18px" display="flex" alignItems="center">
              {storeLogo && (
                <Box width="24px" height="24px" marginRight="8px">
                  <img width="100%" height="100%" src={storeLogo} alt={storeName} />
                </Box>
              )}
              {detailsLinkText({ storeName })}
              <PreviewIcon />
            </StyledProductLink>
          )}
        </Box>
      </LayoutShell.MediaPanel>
      <LayoutShell.ContentPanel>
        {product.mustHave && <MustHaveChip />}
        <TextV2 typographyVariant="hed3" marginBottom={1}>
          {product.brand}
        </TextV2>
        <StyledProductTitle typographyVariant="hed5" marginBottom={3}>
          {product.title}
        </StyledProductTitle>
        {isPricedProduct(product) && (
          <Flex gap={2} marginBottom={8}>
            <StyledProductPrice textDecoration={!product.isFullPrice ? 'line-through' : ''} typographyVariant="hed4" color="mono13">
              <FormattedCurrency
                priceFloatingPointDecimalString={!product.isFullPrice ? product.fullPrice || '' : product.floatingPointDecimalString}
                priceCurrencyCode={!product.isFullPrice ? product.fullPriceCurrencyCode : product.currencyCode}
                formatForm="short"
              />
            </StyledProductPrice>
            {!product.isFullPrice && (
              <TextV2 as="span" fontWeight={500} width="max-content" typographyVariant="hed4" color="#D80B0B">
                {message()}{' '}
                <FormattedCurrency priceFloatingPointDecimalString={product.floatingPointDecimalString || ''} priceCurrencyCode={product.currencyCode} formatForm="short" />
              </TextV2>
            )}
            {hasToShowMsrp && <MsrpTooltip msrpDescription={msrpTranslations.msrpDescription} msrpTitle={msrpTranslations.msrpTitle} />}
          </Flex>
        )}
        {product.description && (
          <Box marginBottom={6}>
            <TextV2 as="span" typographyVariant="hed2" fontWeight={700} color="gray12" marginBottom="8px">
              {descriptionLabel()}
            </TextV2>
            <StyledDescription typographyVariant={'body2'} isDescriptionShowed={isDescriptionShowed}>
              {product?.description}
            </StyledDescription>

            <ButtonV2 variant="link" intent="neutral" typographyVariant="body1" lineHeight="tall" width="fit-content" color="mono14" onClick={handleOnReadMoreClick}>
              {isDescriptionShowed ? hideDescription() : readMore()}
            </ButtonV2>
          </Box>
        )}
        <Flex justifyContent="center" flexDirection="column">
          {recentGiftStatus?.alreadyReserved ? (
            <Box>
              <TextV2 typographyVariant="hed4" paddingRight={2}>
                {alreadyReservedTitle()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7} paddingRight={2}>
                {alreadyReservedSubtitle()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7}>
                {alreadyReservedCheckEmail({ email: recentGiftStatus.usedEmail })}
              </TextV2>
            </Box>
          ) : (
            <>
              {product?.requested > 1 && (
                <Flex justifyContent="space-between" alignItems="center" marginBottom={6}>
                  <Flex flexDirection="column" flex="2 0 120px">
                    <StyledHowManyRequestLabel as="p" typographyVariant={'body1'} lineHeight="tall">
                      {howManyRequest()}
                    </StyledHowManyRequestLabel>
                    <StyledNeededLabel as="p" typographyVariant={'body1'} lineHeight="tall">
                      {needed({ StillNeeded: product.stillNeeded })}
                    </StyledNeededLabel>
                  </Flex>

                  <Flex flex="1 2 auto" maxWidth="100px" marginLeft="24px">
                    {product?.requested > DROPDOWN_QUANTITY_LIMIT ? (
                      <ItemCounter
                        increaseCounter={() => {
                          formik.setFieldValue('quantity', formik.values.quantity + 1);
                        }}
                        disableIncrease={formik.values.quantity === product?.stillNeeded}
                        decreaseCounter={() => {
                          formik.setFieldValue('quantity', formik.values.quantity - 1);
                        }}
                        counter={formik.values.quantity}
                        disableRemoveOption={true}
                      />
                    ) : (
                      <SelectV1
                        defaultValue={stillNeededOptions[0]}
                        searchable={false}
                        options={stillNeededOptions}
                        fluid={false}
                        onChange={e => {
                          formik.setFieldValue('quantity', Number.parseInt(e?.value || '1'));
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
              )}
              <Flex flexDirection="column" marginBottom="14px">
                <StyledLabelForm htmlFor="name">{fieldNameLabel()}</StyledLabelForm>
                <InputV2
                  placeholder={fieldNamePlaceholder()}
                  {...formik.getFieldProps('name')}
                  isInvalid={!!formik.getFieldMeta('name').error && !!formik.getFieldMeta('name').touched}
                />
                <StyledInputError className="inputError">{formik.errors.name && formik.touched.name && formik.errors.name}</StyledInputError>
              </Flex>
              <Flex flexDirection="column" marginBottom="14px">
                <StyledLabelForm htmlFor="email">{fieldEmailLabel()}</StyledLabelForm>
                {mySessionEmail ? (
                  <>
                    <TextV2 typographyVariant="body1">{mySessionEmail}</TextV2>
                    <LinkV2
                      typographyVariant="body1"
                      textDecoration="none"
                      onClick={() => handleOnChangeEmail(mySessionEmail)}
                      marginTop={1}
                      marginBottom={'22px'}
                      color="mono14"
                      fontWeight={600}
                    >
                      {changeEmail()}
                    </LinkV2>
                  </>
                ) : (
                  <>
                    <InputV2
                      placeholder={fieldEmailPlaceholder()}
                      {...formik.getFieldProps('email')}
                      isInvalid={!!formik.getFieldMeta('email').error && !!formik.getFieldMeta('email').touched}
                    />
                    <StyledInputError className="inputError">{formik.errors.email && formik.touched.email && formik.errors.email}</StyledInputError>
                  </>
                )}
              </Flex>
              <Flex flexDirection="column" marginBottom="14px">
                <StyledLabelForm htmlFor="note">{fieldNoteLabel()}</StyledLabelForm>
                <TextArea {...formik.getFieldProps('note')} placeholder={fieldNotePlaceholder()} resize={false} minRows={3} maxRows={3} tabIndex={0} />
                <StyledInputError className="inputError">{formik.errors.note && formik.touched.note && formik.errors.note}</StyledInputError>
              </Flex>
              <Flex flexDirection="column" alignItems="stretch" rowGap={5} minHeight="166px">
                <ButtonV2
                  intent="neutral"
                  shape="rounded"
                  disabled={isOutOfStock || !!purchaseErrorContent}
                  loading={createOrderLoading || preparingForDropshipPurchaseLoading || updateRegistryItemLoading}
                  onClick={() => {
                    handleOnAffiliatePrimaryCTAClick();
                  }}
                >
                  {isOutOfStock || !isPurchasable ? outOfStock() : formButtonBuyNow()}
                </ButtonV2>
                {purchaseErrorContent && <StyledPurchasableMessage typographyVariant="label2">{purchaseErrorContent}</StyledPurchasableMessage>}

                <ButtonV2 intent="neutral" color="mono14" variant="ghost" onClick={handleOnAffiliateMarkAsPurchasedClick}>
                  {formButtonMarkAsPurchased()}
                </ButtonV2>
                {isDropshipable ? (
                  <TextV2 tagName="p" typographyVariant="label1" color="mono12" textAlign="center">
                    {product.externallyOwned ? (product.storeName ? fulfilledByExternal({ storeName: product.storeName }) : '') : fulfilledByJoy()}
                  </TextV2>
                ) : (
                  <LaunchEmailSupportLink>
                    <ChatIcon />
                    {questionV2LinkText()}
                  </LaunchEmailSupportLink>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </LayoutShell.ContentPanel>
    </LayoutShell>
  );
};
