import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import { ButtonV2 } from '@withjoy/joykit';
import React, { useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { usePurchaseDialogController } from '../../routes/GuestRegistry/components/PurchaseDialog/PurchaseDialog.controller';
import GiftWrapOffer from '../CheckoutDialog/components/GiftWrapOffer';
import { useTranslation } from '@shared/core';
import { prepareECardPayload } from '@shared/components';
import { useGiftWrapECardPrice, useSaveCardDraft } from '@shared/components/GiftWrap';
import { GiftWrapType } from '../../GuestRegistry.telemetry';
import { useShoppingCartTelemetry } from '@apps/registry/common/components/ShoppingCart/ShoppingCart.telemetry';

export interface CheckoutGiftCardProps {
  eventHandle: string;
  eventId: string;
  formik: ReturnType<typeof usePurchaseDialogController>['formik'];
  isLoading: boolean;
  handleOnAddGiftNoteClick: () => void;
  handleOnAddGreetingCardClick: () => void;
  isIn: boolean;
  product: CookedProduct;
}

export const CheckoutGiftCard: React.FC<CheckoutGiftCardProps> = ({
  eventId,
  eventHandle,
  formik,
  isIn,
  product,
  handleOnAddGreetingCardClick,
  handleOnAddGiftNoteClick,
  isLoading
}) => {
  const { t } = useTranslation('guestRegistry');
  const tGiftCard = t('checkoutGiftCard');
  const {
    giftWrapOfferViewed,
    giftWrapPromptSent,
    giftWrapMessageEdited,
    addCardGiftWrapClicked,
    giftWrapDraftSaveError,
    giftWrapEditMessageClicked,
    viewMoreDesignsClicked,
    uneditedWarningShown
  } = useShoppingCartTelemetry();

  const [saveCardDraft, isSaving] = useSaveCardDraft(giftWrapDraftSaveError);
  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();
  const PRICE = `$${registryGiftWrapEcardPrice}`;
  const defaultThemeIDRef = useRef<string>('');
  const themeHistoryRef = useRef<string[]>([]);
  const themeOrderRef = useRef<string[]>([]);
  const offerViewedSent = useRef(false);

  useEffect(() => {
    if (isIn && offerViewedSent.current !== true && defaultThemeIDRef.current && themeOrderRef.current.length > 0) {
      giftWrapOfferViewed({ eventHandle, type: GiftWrapType.BUY_NOW, defaultDesign: defaultThemeIDRef.current ?? 'none', designOrder: themeOrderRef.current.toString() });
      offerViewedSent.current = true;
    }
  }, [isIn, giftWrapOfferViewed, eventHandle]);

  const styleProps = useSpring({
    opacity: isIn ? 1 : 0,
    display: isIn ? 'flex' : 'none',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    config: { duration: isIn ? 350 : 250, easing: cubicBezierEasingFn },
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem'
    // fadeIn: 350 milliseconds | fadeOut: 250 milliseconds
  });

  let productLink: string | undefined;
  try {
    const origin = window?.location?.origin ?? 'https://withjoy.com';
    productLink = new URL(`${eventHandle}/edit/registry/track`, origin)?.href;
  } catch (e) {}

  const handleAddGiftWrap = async (message: string, themeId: string) => {
    addCardGiftWrapClicked({
      eventHandle,
      design: themeId ?? 'none',
      giftName: product?.title,
      giftType: product?.registryItemType || 'giftType',
      message,
      price: PRICE,
      defaultDesign: defaultThemeIDRef.current ?? 'none',
      designsPreviewed: themeHistoryRef.current.toString(),
      designOrder: themeOrderRef.current.toString()
    });

    const payload = {
      message,
      themeId,
      image: product?.image,
      title: product?.title,
      giftLink: productLink ?? ''
    };
    const draftId = await saveCardDraft(eventId, payload);

    if (draftId && typeof draftId === 'string') {
      const eCard = prepareECardPayload({
        price: registryGiftWrapEcardPrice,
        draftId,
        theme: themeId ?? 'none',
        imageURL: product?.image,
        offerType: GiftWrapType.BUY_NOW
      });
      formik.setFieldValue('eCard', eCard);
    } else {
      formik.setFieldValue('eCard', undefined);
      alert('Oops! Something went wrong while creating your eCard. The eCard has been removed from your order and you will continue to checkout.');
      throw new Error(`Returned Email Draft ID is not a string: ${draftId}`);
    }
    formik.setFieldValue('note', message);
    handleOnAddGreetingCardClick();
  };

  const handleOnAddGiftNote = (message: string) => {
    formik.setFieldValue('eCard', undefined);
    formik.setFieldValue('note', message);
    handleOnAddGiftNoteClick();
  };

  const handleSetThemeId = (themeId: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => {
    defaultThemeIDRef.current = defaultThemeID;
    themeHistoryRef.current = themeHistory;
    themeOrderRef.current = themeOrder;
  };

  return (
    <animated.div style={styleProps}>
      <GiftWrapOffer
        skip={!isIn || !formik.values.name}
        product={product}
        giverName={formik.values.name}
        giftName={product.title}
        giftWrapMessageEdited={giftWrapMessageEdited}
        giftWrapPromptSent={giftWrapPromptSent}
        giftWrapEditMessageClicked={giftWrapEditMessageClicked}
        giftWrapUneditedMessageShown={uneditedWarningShown}
        viewMoreDesignsClicked={viewMoreDesignsClicked}
        onSetThemeId={handleSetThemeId}
        onButtonClick={({ wrapOption, message, themeId }) => (wrapOption === 'eCard' ? handleAddGiftWrap(message, themeId) : handleOnAddGiftNote(message))}
      >
        {({ onClick }) => (
          <ButtonV2
            alignSelf="center"
            intent="productive"
            minWidth="20rem"
            marginTop="1.5rem !important"
            marginBottom={[0, null, 5]}
            size="lg"
            onClick={onClick}
            loading={isLoading || isSaving}
          >
            {tGiftCard.next()}
          </ButtonV2>
        )}
      </GiftWrapOffer>
    </animated.div>
  );
};
