import { DonationFundPaymentMethodEnum, EventType } from '@graphql/generated';
import { Checkbox, generateGiftWrapPrompt, getGiftWrapThemeListByEventType } from '@shared/components';
import { GiftWrapEditor, GiftWrapProduct, useGiftWrapECardPrice } from '@shared/components/GiftWrap';
import { useTranslation } from '@shared/core';
import { useEventInfo } from '@shared/utils/eventInfo';
import { SpacingStack, TextV2, Box, Flex, Divider, TextArea, useDisclosure } from '@withjoy/joykit';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { CheckboxContainer, styles } from './GiftWrapOffer.styles';
import { useFeatureValue } from '@shared/core/featureFlags';
import UneditedMessageWarning from '@apps/registry/common/components/ShoppingCart/components/UneditedMessageWarning';

export type GiftWrapOption = 'eCard' | 'giftNote';

export type GiftWrapOfferState = { message: string; themeId: string; wrapOption: GiftWrapOption; isMessageEdited: boolean };

interface GiftWrapOfferProps {
  initialMessage?: string;
  skip?: boolean;
  giverName: string;
  giftName: string;
  product?: (GiftWrapProduct & { platform?: DonationFundPaymentMethodEnum }) | null;
  onChange?: (data: GiftWrapOfferState) => void;
  giftWrapEditMessageClicked?: (data: { label: string }) => void;
  giftWrapPromptSent?: (data: { prompt: string }) => void;
  giftWrapMessageEdited?: (data: { originalNote: string; modifiedNote: string }) => void;
  giftWrapUneditedMessageShown?: () => void;
  viewMoreDesignsClicked?: (show: boolean) => void;
  onSetThemeId?: (id: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => void;
  children?: (data: { onClick: () => void }) => React.ReactElement;
  onButtonClick?: (data: GiftWrapOfferState) => unknown;
  onContinue?: () => void;
}

const GiftWrapOffer: React.FC<GiftWrapOfferProps> = ({
  initialMessage,
  skip,
  giverName,
  giftName,
  product,
  giftWrapEditMessageClicked,
  giftWrapPromptSent,
  giftWrapMessageEdited,
  giftWrapUneditedMessageShown,
  viewMoreDesignsClicked,
  children,
  onButtonClick,
  onContinue,
  onChange,
  onSetThemeId
}) => {
  const PRICE = useGiftWrapECardPrice();
  const { eventInfo } = useEventInfo();
  const [wrapOption, setWrapOption] = useState<GiftWrapOption>('eCard');
  const [savedWrapMessage, saveWrapMessage] = useState('');
  const [message, setMessage] = useState(initialMessage || '');
  const themes = getGiftWrapThemeListByEventType(eventInfo?.eventType);
  const [themeId, setThemeId] = useState<string>('');
  const { t } = useTranslation('sharedRegistry');
  const tShoppingCart = t('shoppingCart');
  const defaultThemeIDRef = useRef<string>('');
  const themeHistoryRef = useRef<string[]>([]);
  const themeOrderRef = useRef<string[]>([]);
  const [isMessageEdited, setIsMessageEdited] = useState(false);
  const uneditedMessageWarning = useDisclosure();
  const { value: randomizeThemeOrder } = useFeatureValue('registryGiftWrapRandomizeThemeOrder');

  useEffect(() => onChange?.({ wrapOption, message, themeId, isMessageEdited }), [message, wrapOption, themeId, isMessageEdited, onChange]);

  const handleWrapClick = () => {
    if (wrapOption === 'eCard') {
      return;
    }

    setWrapOption('eCard');
    handleWrapMessageChange(savedWrapMessage);
  };

  const handleMessageClick = (e: SyntheticEvent<unknown>) => {
    if (wrapOption === 'giftNote') {
      return;
    }
    // we are navigating from gift wrap to basic, so clear the eCard info
    e.stopPropagation();
    e.preventDefault();
    setWrapOption('giftNote');
    setMessage('');
  };

  const handleWrapMessageChange = (message: string) => {
    saveWrapMessage(message);
    setMessage(message);
  };

  const handleBlur = ({ originalNote, message }: { originalNote: string; message: string }) => {
    giftWrapMessageEdited?.({ originalNote, modifiedNote: message });
    if (!isMessageEdited) {
      setIsMessageEdited(true);
    }
  };

  const handleSetThemeId = (themeId: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => {
    defaultThemeIDRef.current = defaultThemeID;
    themeHistoryRef.current = themeHistory;
    themeOrderRef.current = themeOrder;
    onSetThemeId?.(themeId, defaultThemeID, themeHistory, themeOrder);
    setThemeId(themeId);
  };

  const handleButtonClick = () => {
    if (!isMessageEdited && wrapOption === 'eCard') {
      uneditedMessageWarning.onOpen();
      giftWrapUneditedMessageShown?.();
      return false;
    }

    onButtonClick?.({ message, themeId, wrapOption, isMessageEdited });
    return true;
  };

  const handleContinueClick = () => {
    uneditedMessageWarning.onClose();
    onButtonClick?.({ message, themeId, wrapOption, isMessageEdited });
    onContinue?.();
  };

  const isgiftWrapOptionsOrderReversed = useFeatureValue('giftWrapOptionsOrderExperiment')?.value === 'treatment';

  return (
    <SpacingStack spacing={3} paddingX={0} paddingTop={0} maxWidth="475px" width="100%">
      <UneditedMessageWarning isOpen={uneditedMessageWarning.isOpen} onClose={uneditedMessageWarning.onClose} onContinue={handleContinueClick} />
      <TextV2 typographyVariant="label3">{tShoppingCart.messageStep.title()}</TextV2>
      <Flex flexDirection={isgiftWrapOptionsOrderReversed ? 'column-reverse' : 'column'}>
        <Box border="1px solid" borderColor="mono3" borderRadius="4px" paddingTop={1}>
          <Flex alignItems="center" padding={5} role="button" onClick={handleWrapClick} cursor="pointer">
            <CheckboxContainer>
              <Checkbox id="card" checked={wrapOption === 'eCard'} onChange={handleWrapClick} />
            </CheckboxContainer>
            <Flex flexDirection="column" marginLeft={5} flexGrow={1}>
              <Flex justifyContent="space-between" marginBottom={1}>
                <TextV2 typographyVariant="button1">{tShoppingCart.messageStep.wrapTitle()}</TextV2>
                <TextV2 typographyVariant="label2" color="mono10">
                  ${PRICE}
                </TextV2>
              </Flex>
              <TextV2 typographyVariant="label2" color="mono12">
                {tShoppingCart.messageStep.wrapDescription()}
              </TextV2>
            </Flex>
          </Flex>
          {wrapOption === 'eCard' && (
            <Box maxWidth={'500px'}>
              <Flex paddingBottom={5} paddingX={5}>
                <Divider />
              </Flex>
              <GiftWrapEditor
                onEditMessageClick={label => giftWrapEditMessageClicked?.({ label })}
                onPromptSent={prompt => giftWrapPromptSent?.({ prompt })}
                onBlur={handleBlur}
                skip={!!savedWrapMessage || skip}
                product={product}
                prompt={generateGiftWrapPrompt({
                  from: giverName,
                  firstPartner: eventInfo?.ownerFirstName || '',
                  secondPartner: eventInfo?.fianceeFirstName || '',
                  giftName,
                  context: eventInfo?.eventType === EventType.babyRegistry ? 'baby' : 'wedding'
                })}
                message={message}
                setMessage={handleWrapMessageChange}
                themes={themes}
                setThemeId={handleSetThemeId}
                onViewMoreThemesClick={viewMoreDesignsClicked}
                randomizeThemeOrder={!!randomizeThemeOrder}
              />
            </Box>
          )}
        </Box>
        <Box __css={styles.basicGiftNoteContainer}>
          <Flex alignItems="center" padding={5} role="button" onClick={handleMessageClick} cursor="pointer">
            <CheckboxContainer>
              <Checkbox id="basic" checked={wrapOption === 'giftNote'} onChange={handleMessageClick} />
            </CheckboxContainer>
            <TextV2 typographyVariant="button1" marginLeft={5}>
              {tShoppingCart.messageStep.noteTitle()}
            </TextV2>
          </Flex>
          {wrapOption === 'giftNote' && (
            <Box paddingBottom={5} paddingX={5}>
              <Flex paddingBottom={5}>
                <Divider />
              </Flex>
              <TextV2 typographyVariant="label3" marginBottom={3}>
                {tShoppingCart.messageStep.textareaLabel()}
              </TextV2>
              <TextArea
                maxLength={product?.platform === DonationFundPaymentMethodEnum.venmo ? 280 : undefined}
                placeholder={tShoppingCart.messageStep.textAreaPlaceholder()}
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </Box>
          )}
        </Box>
      </Flex>
      {children?.({ onClick: handleButtonClick })}
    </SpacingStack>
  );
};

export default GiftWrapOffer;
