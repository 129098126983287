import React from 'react';
import { Box, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import { StyledDivider } from '../../../RegistryDialog/DialogFooter.styles';
import { InSummaryItem } from '../../../ShoppingCart/components/SummaryItemList/InSummaryItem';
import { pxToRem } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';
import { MonetaryValue, useExpressCheckoutContext } from '../../ExpressCheckout.provider';
import { useCurrencyContext } from '@shared/utils/currency';

export const PriceLine = ({ label, price, bold }: { label: string; price: string | number; bold?: boolean }) => (
  <Flex justifyContent={'space-between'}>
    <TextV2 typographyVariant={bold ? 'hed3' : 'label2'} color={bold ? 'mono14' : 'mono12'}>
      {label}
    </TextV2>
    <TextV2 typographyVariant={bold ? 'hed3' : 'label2'} color={bold ? 'mono14' : 'mono12'}>
      {price}
    </TextV2>
  </Flex>
);

export const PaymentSummary = () => {
  const { t } = useTranslation('sharedRegistry');
  const { subtotal, total, salesTax, delivery, freeDeliveryPriceText, pendingSalesTaxPriceText, eCard } = t('shoppingCart');
  const currency = useCurrencyContext();
  const {
    state: { purchaseProducts, shippingCost, giftWrap, totalPrice, productsPrice, eCardPrice }
  } = useExpressCheckoutContext();

  const formatPrice = (value: MonetaryValue) => currency.formatCurrency(value.valueInMinorUnits / 100, { form: 'short' });

  const formatPriceOrPending = (value?: MonetaryValue | null) => {
    return value ? formatPrice(value) : pendingSalesTaxPriceText();
  };

  return (
    <>
      <Box marginTop={pxToRem(-24)} marginRight={pxToRem(-16)}>
        {purchaseProducts.map(product => (
          <InSummaryItem
            key={product.registryItemId}
            inCartProduct={{ product, priceInMinorUnits: product.valueInMinorUnits, quantity: product.quantity, registryItemId: product.registryItemId }}
          />
        ))}
      </Box>
      <StyledDivider />
      <SpacingStack spacing={pxToRem(8)}>
        <PriceLine label={subtotal()} price={formatPrice(productsPrice)} />
        <PriceLine label={delivery()} price={shippingCost?.valueInMinorUnits === 0 ? freeDeliveryPriceText() : formatPriceOrPending(shippingCost)} />
        {giftWrap?.wrapOption === 'eCard' && <PriceLine label={eCard()} price={formatPriceOrPending(eCardPrice)} />}
        <PriceLine label={salesTax()} price={pendingSalesTaxPriceText()} />
      </SpacingStack>
      <StyledDivider />
      <PriceLine label={total()} price={formatPriceOrPending(totalPrice)} bold />
    </>
  );
};
