import React from 'react';
import { LoadingExpressCheckoutElement } from '@shared/core/stripe';
import { useExpressCheckoutContext } from '../../ExpressCheckout.provider';
import { useTranslation } from '@shared/core';
import { TextV2 } from '@withjoy/joykit';

export const ExpressCheckoutButton = () => {
  const {
    state: { giftWrap },
    mutators: { handleCheckoutClick, handleCheckoutConfirm }
  } = useExpressCheckoutContext();
  const { t2 } = useTranslation('guestRegistry');
  const { giftWrapUneditedMessageWarning } = t2('expressCheckout');

  return (
    <>
      {giftWrap?.wrapOption === 'eCard' && !giftWrap.isMessageEdited && (
        <TextV2 typographyVariant={'body1'} color={'mono12'} textAlign={'center'}>
          {giftWrapUneditedMessageWarning}
        </TextV2>
      )}
      <LoadingExpressCheckoutElement onClick={handleCheckoutClick} onConfirm={handleCheckoutConfirm} options={{ buttonHeight: 50 }} />
    </>
  );
};
