import { Box, styled, StyleSystemProps, css } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const AddressAtomWrapper = styled.button<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(20)} ${pxToRem(16)};
  border-radius: ${pxToRem(4)};
  border-width: ${props => (props.isSelected ? pxToRem(2) : pxToRem(1))};
  border-color: ${props => (props.isSelected ? props.theme.colors.mono14 : props.theme.colors.mono3)};
  border-style: solid;
  background-color: ${props => props.theme.colors.white};
  width: 100%;

  ${props =>
    !props.isSelected &&
    css`
      &:hover {
        border-color: ${props.theme.colors.mono5};
        background-color: ${props.theme.colors.mono1};

        input + label .checkbox_svg__check-circle {
          stroke: ${props => props.theme.colors.mono7};
        }
      }

      &:focus {
        border-color: ${props.theme.colors.mono7};
        background-color: ${props.theme.colors.mono2};

        input + label .checkbox_svg__check-circle {
          stroke: ${props => props.theme.colors.mono9};
        }
      }
    `}
`;

export const AddressAtomCheckbox = styled(Box)`
  input:checked + label .checkbox_svg__check-circle {
    fill: ${props => props.theme.colors.mono14};
    stroke: ${props => props.theme.colors.mono14};
  }
`;

export const TooltipIcon = styled.button`
  &:hover > svg {
    color: ${props => props.theme.colors.mono12};
  }
  &:focus > svg {
    color: ${props => props.theme.colors.mono14};
  }
`;
const addressAtomWrapperStyles: StyleSystemProps = {
  display: 'flex',
  flexDirection: 'column',
  paddingX: 5,
  paddingY: pxToRem(20),
  borderRadius: pxToRem(4),
  border: '1px solid',
  borderColor: 'mono3'
};

const addressAtomWarningWrapperStyles: StyleSystemProps = {
  ...addressAtomWrapperStyles,
  paddingY: 5,
  borderColor: 'negative2',
  backgroundColor: 'negative1',
  flexDirection: 'row',
  alignItems: 'center'
};

const addressAtomCheckboxStyles = {
  marginRight: 5
};

export const styles = {
  addressAtomWrapperStyles,
  addressAtomWarningWrapperStyles,
  addressAtomCheckboxStyles
};
