import React from 'react';

import { CookedProduct, CookedProductList, calculateNumberPrice } from '@apps/registry/common/selectors/ProductListSelector';
import { RegistryOrderFragment, useGetRegistryPurchaseConfirmationDetailsQuery } from '@graphql/generated';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { useCallback } from 'react';
import { JoyPurchasedOrder } from './JoyPurchasedOrder';
import { StyledWrapper, styles } from './JoyPurchasedOrder.styles';
import { SkeletonGroup, SkeletonText } from '@shared/components/Skeleton';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { deriveCurrencyCode } from './JoyPurchasedOrder.utils';

type JoyPurchasedOrderWrapperProps = {
  order: RegistryOrderFragment;
  products: readonly CookedProduct[];
};

export const JoyPurchasedOrderWrapper = ({ order, products }: JoyPurchasedOrderWrapperProps) => {
  const { formatCurrency } = useCurrencyFormatter();

  const { data, loading } = useGetRegistryPurchaseConfirmationDetailsQuery({
    batchMode: 'fast',
    variables: order.id ? { purchaseContextId: order.id } : undefined,
    skip: !order.id
  });

  const formatJoyPurchasedOrder = useCallback(
    (orderList: RegistryOrderFragment, products: CookedProductList['products']) => {
      const formattedOrderList = orderList.lineItems.reduce((acc, lineItem) => {
        if (lineItem.registryItem) {
          const product = products.find(product => product.id === lineItem?.registryItem?.id);
          const frozenPrice = calculateNumberPrice(lineItem.frozenProductData.price);
          if (!product) {
            return acc;
          }
          return [
            ...acc,
            {
              product,
              quantity: lineItem.quantity,
              price: lineItem.frozenProductData.price.floatingPointDecimalString,
              totalPrice: formatCurrency({
                priceFloatingPointDecimalString: `${frozenPrice * lineItem.quantity}`,
                priceCurrencyCode: lineItem.frozenProductData.price.currency?.code || DEFAULT_CURRENCY,
                formatForm: 'short'
              }),
              siteName: product?.brand
            }
          ];
        }

        return acc;
      }, [] as Array<{ product: Partial<CookedProduct>; quantity: number; price: string; totalPrice: string; siteName?: string | null }>);

      return { orderList: formattedOrderList, ...orderList };
    },
    [formatCurrency]
  );
  const getItemPhoto = useCallback(item => (item?.photos[0] !== undefined ? item.photos[0].url : undefined), []);

  const salesOrder = data?.registryPurchaseConfirmationDetailsByPurchaseContextId?.salesOrder;
  const items = salesOrder?.items || [];
  const brand = salesOrder?.paymentMethod?.card?.brand || '';
  const last4 = salesOrder?.paymentMethod?.card?.last4 || '';
  const isEdelivery = items.length > 0 ? items[0].isEdelivery : false;
  const priceCurrencyCode = deriveCurrencyCode(salesOrder);
  const orderList = items.map(item => ({
    product: { title: item.title, image: getItemPhoto(item) },
    quantity: item.quantity,
    price: formatCurrency({
      priceFloatingPointDecimalString: item.unitValue.floatingPointDecimalString,
      priceCurrencyCode,
      formatForm: 'short'
    }),
    totalPrice: formatCurrency({
      priceFloatingPointDecimalString: item.lineValue.floatingPointDecimalString,
      priceCurrencyCode,
      formatForm: 'short'
    })
  }));

  return (
    <StyledWrapper __css={styles.wrapperStyles}>
      <SkeletonGroup
        isReady={!loading}
        placeholder={
          <>
            <SkeletonText marginBottom={2} width="100%" skeletonHeight="31px" rows={1} />
            <SkeletonText marginBottom={6} width="100%" skeletonHeight="15px" rows={1} />
            <SkeletonText marginBottom={2} width="100%" skeletonHeight="31px" rows={1} />
            <SkeletonText marginBottom={6} width="100%" skeletonHeight="60px" rows={1} />
            <SkeletonText marginBottom={2} width="100%" skeletonHeight="31px" rows={1} />
            <SkeletonText marginBottom={6} width="100%" skeletonHeight="100px" rows={1} />
          </>
        }
      >
        {data && (
          <JoyPurchasedOrder
            {...formatJoyPurchasedOrder(order, products)}
            variant="normal"
            orderList={orderList}
            salesOrder={salesOrder}
            brand={brand}
            last4={last4}
            isEdelivery={isEdelivery}
            {...order}
          />
        )}
      </SkeletonGroup>
    </StyledWrapper>
  );
};
