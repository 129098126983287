import { Box, TextV2, SpacingStack } from '@withjoy/joykit';
import React from 'react';
import { WarningAddressAtom, ShippingAddressAtom } from '../../../ShoppingCart/components';
import { StandardShippingOption } from '../../../ShoppingCart/components/AddressAtoms/StandardShipping';
import { ExpressCheckoutShippingOption, useExpressCheckoutContext } from '../../ExpressCheckout.provider';
import { useTranslation } from '@shared/core';
import { useCurrencyContext } from '@shared/utils/currency';

export const ShippingSettings = () => {
  const { t2 } = useTranslation('guestRegistry');
  const {
    shippingMethod: shippingMethodLabel,
    freeShipping,
    shipTo,
    giftEmoji,
    onlyUSAddress,
    shipToMe,
    shippingAddressDuringPayment,
    standardShipping,
    shippingAddressPending
  } = t2('expressCheckout', 'shippingAddress');
  const {
    state: { coupleAddress, shippingCost, shippingOption },
    mutators: { setShippingOption }
  } = useExpressCheckoutContext();
  const currency = useCurrencyContext();

  const createAddressLabel = (address: string) => {
    return `${giftEmoji} ${address}`;
  };

  return (
    <>
      <Box>
        <TextV2 typographyVariant="label3" marginBottom={3}>
          {shippingMethodLabel}
        </TextV2>
        <StandardShippingOption
          label={shippingCost ? (shippingCost.valueInMinorUnits === 0 ? freeShipping : standardShipping) : shippingAddressPending}
          value={shippingCost ? currency.formatCurrency(shippingCost.valueInMinorUnits / 100, { form: 'short' }) : ''}
        />
      </Box>
      <Box>
        <TextV2 typographyVariant="label3" marginBottom={3}>
          {shipTo}
        </TextV2>
        <SpacingStack spacing={3} width="100%">
          {!coupleAddress?.name ? (
            <>
              <WarningAddressAtom />
            </>
          ) : (
            <ShippingAddressAtom
              isSelected={shippingOption === ExpressCheckoutShippingOption.CoupleAddress}
              label={createAddressLabel(coupleAddress.name)}
              note={`${coupleAddress.city}, ${coupleAddress.state}, ${coupleAddress.postalCode}`}
              onSelect={() => {
                setShippingOption(ExpressCheckoutShippingOption.CoupleAddress);
              }}
            />
          )}
          <ShippingAddressAtom
            isSelected={shippingOption === ExpressCheckoutShippingOption.ShipToMe}
            label={createAddressLabel(shipToMe)}
            note={shippingAddressDuringPayment}
            tooltip={onlyUSAddress}
            onSelect={() => {
              setShippingOption(ExpressCheckoutShippingOption.ShipToMe);
            }}
          />
        </SpacingStack>
      </Box>
    </>
  );
};
