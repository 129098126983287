import React from 'react';
import { InReceiptItem } from '../../InReceiptItem';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { StyledSummary, StyledSummaryListItemContainer, styles } from './JoyPurchasedOrder.styles';
import { Box, Divider } from '@withjoy/joykit';
import { ButtonV2, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import { FormattedCurrency } from '@shared/utils/currency';
import { useTranslation } from '@shared/core';
import { GiftNoteV2, ItemBaseVariant, OrderNumberMetadata, TrackingMetadata } from '../../../../../../../../components';
import { RegistryOrderFragment } from '@graphql/generated';
import { DateFragment } from '@graphql/generated';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { StyledTrackingInfoBox, styles as purchasedItemStyles } from '../PurchasedItem/PurchasedItem.styles';
import { Chat } from './icons';
import { useGiftWrapECardPrice } from '@shared/components';
import { SalesOrder } from './JoyPurchasedOrder.types';
import { calcStripeFee, calcSubtotal } from './JoyPurchasedOrder.utils';

type Order = { product: Partial<CookedProduct>; quantity: number; price: string; totalPrice: string; siteName?: string | null };

type JoyPurchasedOrderProps = Partial<RegistryOrderFragment> & {
  orderList: Array<Order>;
  salesOrder: SalesOrder;
  isEdelivery?: boolean;
  brand?: string;
  brandText?: string;
  last4?: string;
  variant?: ItemBaseVariant;
};

type FormatDateOptions = {
  format?: string;
};
export const formatDate = (date: Maybe<DateFragment>, options: FormatDateOptions = {}) => {
  const formatString = options.format || 'MMMM d, yyyy';

  const eventDate = date?.milliseconds || new Date();
  const dateWithTimezoneFormatting = utcToZonedTime(eventDate, date?.timezone || 'America/Los_Angeles');
  return format(dateWithTimezoneFormatting, formatString);
};

export const JoyPurchasedOrder = ({ orderList, salesOrder, isEdelivery, brand, brandText, last4, orderedAt, variant, fulfillment, note, id }: JoyPurchasedOrderProps) => {
  const { t } = useTranslation('sharedRegistry');
  const { subtotal, delivery, salesTax, total, paidNote, items, item, ordered, eCardFee, stripeFee } = t('shoppingCart');
  const { returnPolicy, contactSupport } = t('shoppingCartHelp');
  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();
  const giftWrapPriceAsString = `${registryGiftWrapEcardPrice}`;
  const orderListCount = orderList.reduce((acc, item) => acc + item.quantity, 0);
  const listCountText = `${orderListCount} ${orderListCount === 1 ? item() : items()}`;
  const orderDateText = orderedAt ? `${ordered()} ${formatDate(orderedAt)}` : '';
  // we need to calculate this from the salesOrder object
  const hasGiftWrapCard = false; //hasGiftWrapFromSalesOrderPrice(registryGiftWrapEcardPrice, salesOrder);
  const calculatedSubtotalAsString = `${calcSubtotal(salesOrder)}`;
  const fee = calcStripeFee(salesOrder, hasGiftWrapCard, registryGiftWrapEcardPrice);
  return (
    <Flex
      flexDirection="column"
      backgroundColor={variant === 'normal' ? 'white' : 'trasparent'}
      paddingTop={variant === 'normal' ? 6 : 0}
      paddingBottom={variant === 'normal' ? 6 : 0}
    >
      {orderDateText && (
        <TextV2 typographyVariant="hed2" paddingLeft={5}>
          {orderDateText}
        </TextV2>
      )}
      <StyledTrackingInfoBox __css={purchasedItemStyles.trackingInfoBox}>
        {fulfillment?.orderNumber && <OrderNumberMetadata orderNumber={fulfillment.orderNumber} />}
        {fulfillment?.shipments?.[0]?.trackingInformation?.trackingNumber && (
          <Box marginTop={fulfillment?.orderNumber ? 6 : 0}>
            <TrackingMetadata
              trackingNumber={fulfillment.shipments[0].trackingInformation.trackingNumber}
              shippingCarrier={fulfillment.shipments[0].trackingInformation.company || undefined}
            />
          </Box>
        )}
      </StyledTrackingInfoBox>
      {id && note && <GiftNoteV2 note={note} orderId={id} variant="solid" />}
      <TextV2 typographyVariant="hed1" paddingLeft={5}>
        {listCountText}
      </TextV2>
      {orderList?.length > 0 &&
        orderList.map(({ product, quantity, price, totalPrice, siteName }, idx) => (
          <InReceiptItem
            key={idx}
            product={product}
            quantity={quantity}
            price={price}
            totalPrice={totalPrice}
            isDonationFundItem={false}
            siteName={siteName}
            compact
            variant={variant || 'transparent'}
            sm
          />
        ))}
      <StyledSummary __css={styles.summaryStyles}>
        <Divider />
        <SpacingStack spacing={3} marginY={6}>
          <StyledSummaryListItemContainer __css={styles.summaryListItemContainerStyles}>
            <TextV2 typographyVariant="label2">{subtotal()}</TextV2>
            <TextV2 typographyVariant="label2">
              <FormattedCurrency priceFloatingPointDecimalString={calculatedSubtotalAsString} formatForm="short" />
            </TextV2>
          </StyledSummaryListItemContainer>
          {hasGiftWrapCard && (
            <StyledSummaryListItemContainer __css={styles.summaryListItemContainerStyles}>
              <TextV2 typographyVariant="label2">{eCardFee()}</TextV2>
              <TextV2 typographyVariant="label2">
                <FormattedCurrency priceFloatingPointDecimalString={giftWrapPriceAsString} priceCurrencyCode="USD" formatForm="short" />
              </TextV2>
            </StyledSummaryListItemContainer>
          )}
          {!isEdelivery && (
            <StyledSummaryListItemContainer __css={styles.summaryListItemContainerStyles}>
              <TextV2 typographyVariant="label2">{delivery()}</TextV2>
              <TextV2 typographyVariant="label2">
                <FormattedCurrency
                  priceFloatingPointDecimalString={salesOrder?.shippingAndHandlingTotal?.floatingPointDecimalString ?? '0'}
                  priceCurrencyCode={salesOrder?.shippingAndHandlingTotal?.currency?.code}
                  formatForm="short"
                  canShowFreeLabel
                />
              </TextV2>
            </StyledSummaryListItemContainer>
          )}
          {fee > 0 && (
            <StyledSummaryListItemContainer __css={styles.summaryListItemContainerStyles}>
              <TextV2 typographyVariant="label2">{stripeFee()}</TextV2>
              <TextV2 typographyVariant="label2">
                <FormattedCurrency priceFloatingPointDecimalString={fee.toFixed(2)} priceCurrencyCode="USD" formatForm="short" />
              </TextV2>
            </StyledSummaryListItemContainer>
          )}
          <StyledSummaryListItemContainer __css={styles.summaryListItemContainerStyles}>
            <TextV2 typographyVariant="label2">{salesTax()}</TextV2>
            <TextV2 typographyVariant="label2">
              <FormattedCurrency
                priceFloatingPointDecimalString={salesOrder?.taxesTotal?.floatingPointDecimalString ?? '0'}
                priceCurrencyCode={salesOrder?.taxesTotal?.currency?.code}
                formatForm="short"
              />
            </TextV2>
          </StyledSummaryListItemContainer>
        </SpacingStack>
        <Divider />
        <StyledSummaryListItemContainer __css={styles.summaryListItemContainerStyles} paddingY={4}>
          <TextV2 typographyVariant="hed1">{total()}</TextV2>
          <TextV2 typographyVariant="hed3">
            <FormattedCurrency
              priceFloatingPointDecimalString={salesOrder?.total?.floatingPointDecimalString ?? '0'}
              priceCurrencyCode={salesOrder?.total?.currency?.code}
              formatForm="short"
            />
          </TextV2>
        </StyledSummaryListItemContainer>
        <Flex>{brand && last4 && <TextV2 typographyVariant="label2">{paidNote({ card: brandText, cardLastDigits: last4 })}</TextV2>}</Flex>
      </StyledSummary>
      {id && !note && <GiftNoteV2 note={note} orderId={id} variant="solid" />}
      <ButtonV2 intent="neutral" variant="outline" marginBottom={6} marginX={5}>
        <Flex marginRight={1}>
          <Chat />
        </Flex>
        {contactSupport()}
      </ButtonV2>
      <ButtonV2 typographyVariant="button2" intent="neutral" variant="link" marginX={5}>
        <Flex flexDirection="column">
          {returnPolicy()}
          <Divider />
        </Flex>
      </ButtonV2>
    </Flex>
  );
};
