import React from 'react';
import { getConfig } from '@config';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

const config = getConfig();

const stripePromise = loadStripe(config.stripeKey);

interface StripeElementsProps {
  options?: StripeElementsOptions;
}

export const stripeLazySetupOptions: StripeElementsOptions = {
  mode: 'setup',
  currency: 'usd',
  payment_method_configuration: config.stripePaymentMethodConfig,
  appearance: { variables: { borderRadius: '25px' } }
};

export const StripeElements: React.FC<StripeElementsProps> = props => {
  return <Elements stripe={stripePromise} {...props} />;
};
