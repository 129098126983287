import { Box, ButtonV2, Divider, Flex, OptionType, SelectV1, TextV2 } from '@withjoy/joykit';
import React, { useMemo, useState, useEffect } from 'react';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { isPricedProduct as checkIfIsPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import { usePurchaseDialogController } from '../../../../PurchaseDialog.controller';
import {
  StyledDescriptionTitleContainer,
  StyledOtherGiftLink,
  StyledPurchasableMessage,
  StyledQuantityContainer,
  StyledQuantityInfoWrapper,
  StyledSelectWrapper,
  StyledCTAContainer,
  styles,
  StyledProductTitle,
  StyledProductDescriptionContainer,
  StyledPriceSummaryContainer
} from './ProductInformation.styles';
import { useSpring, animated } from 'react-spring';
import { usePurchaseDialogTranslations } from '../../../../hooks';
import { useShoppingCart } from '../../../../../../../../../common/components/ShoppingCart/state';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { ProductInformationGroupGifting } from './ProductInformationGroupGifting';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { MsrpTooltip } from '@apps/registry/common/components/MsrpTooltip';
import { PurchaseDialogProps } from '../../../../PurchaseDialog';
import { useHistory, useRouterHelper, useTranslation } from '@shared/core';
import { ItemCounter } from '../../../../../../../../../common/components/ShoppingCart/ShoppingCartInnerV2/steps/CartReviewStep/components/InCartSection/components/InCartItem/ItemCounter';
import { LoadingExpressCheckoutElement } from '@shared/core/stripe';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { useFeatureValue } from '@shared/core/featureFlags';

type ProductInformationProps = {
  product: CookedProduct;
  handleOnBuyNowClick: () => void;
  handleOnMarkAsPurchasedClick: () => void;
  handleOnAddToCartClick: () => void;
  formik: ReturnType<typeof usePurchaseDialogController>['formik'];
  isOutOfStock: boolean;
  isPurchasable: boolean;
  isPurchaseError: boolean;
  goBackToRegistry: ReturnType<typeof usePurchaseDialogController>['goBackToRegistry'];
  recentGiftStatus: ReturnType<typeof usePurchaseDialogController>['recentGiftStatus'];
  isIn: boolean;
  closeDialog: () => void;
  canRenderProductValues: boolean;
} & Pick<PurchaseDialogProps, 'msrpTranslations'>;

export const DROPDOWN_QUANTITY_LIMIT = 20;

export const ProductInformation = ({
  product,
  handleOnBuyNowClick,
  handleOnMarkAsPurchasedClick,
  handleOnAddToCartClick,
  formik,
  isOutOfStock,
  isPurchasable,
  isPurchaseError,
  goBackToRegistry,
  recentGiftStatus,
  isIn,
  closeDialog,
  canRenderProductValues,
  msrpTranslations
}: ProductInformationProps) => {
  const {
    formButtonMarkAsPurchased,
    formButtonAddToCart,
    hideDescription,
    readMore,
    needed,
    outOfStock,
    alreadyReservedTitle,
    alreadyReservedSubtitle,
    alreadyReservedCheckEmail,
    formButtonBuyNow,
    purchaseErrorMsg,
    otherGifts,
    quantityTitle,
    subtotalTitle,
    quantityTimesPrice,
    isNotPurchasableText
  } = usePurchaseDialogTranslations();
  const telemetry = useGuestRegistryTelemetry();
  const [isDescriptionShowed, setIsDescriptionShowed] = useState<boolean>(false);
  const routePaths = useRoutePaths();
  const router = useRouterHelper();
  const history = useHistory();

  const {
    state: { itemList }
  } = useShoppingCart();
  const { formatCurrency } = useCurrencyFormatter();
  const { t: translations } = useTranslation('catalogRegistry');
  const { message } = translations('salesPrice');
  const expressCheckoutFeatureFlag = useFeatureValue('registryExpressCheckoutEnabled');

  const stillNeededOptions: OptionType[] =
    product?.stillNeeded > 1 && product?.stillNeeded < DROPDOWN_QUANTITY_LIMIT
      ? Array.from(Array(product.stillNeeded).keys()).map(i => ({ label: `${i + 1}`, value: `${i + 1}` }))
      : [];

  const item = useMemo(() => itemList.find(item => item.registryItemId === product.registryItemId), [itemList, product]);
  const hasToShowMsrp = product?.id && !product?.externallyOwned && !product?.canEditProductData;

  const purchaseErrorContent = useMemo(() => {
    if (!isPurchasable) {
      return isNotPurchasableText({
        link: () => (
          <StyledOtherGiftLink typographyVariant="label2" onClick={goBackToRegistry}>
            {otherGifts()}
          </StyledOtherGiftLink>
        )
      });
    }
    if (isPurchaseError) {
      return purchaseErrorMsg();
    }
    return null;
  }, [isPurchasable, isNotPurchasableText, isPurchaseError, purchaseErrorMsg, goBackToRegistry, otherGifts]);

  const cannotPurchase = isOutOfStock || !isPurchasable;

  const styleProps = useSpring({
    opacity: isIn ? 1 : 0,
    display: isIn ? 'flex' : 'none',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    config: { duration: isIn ? 350 : 250, easing: cubicBezierEasingFn }
    // fadeIn: 350 milliseconds | fadeOut: 250 milliseconds
  });

  // Either the product is not in the shopping cart or it is with a quantity less then it's needed
  const displayButtonAddToCart = (!item || item.quantity < product.stillNeeded) && canRenderProductValues;
  const charmPriceTotal = product?.numberPrice ? `${product.numberPrice * formik.values.quantity}` : '0';
  const totalPrice = formatCurrency({
    priceFloatingPointDecimalString: charmPriceTotal,
    priceCurrencyCode: product.currencyCode,
    formatForm: 'short'
  });

  const fullPrice = formatCurrency({
    priceFloatingPointDecimalString: product?.fullPrice || '',
    priceCurrencyCode: product?.fullPriceCurrencyCode,
    formatForm: 'short'
  });

  useEffect(() => {
    if (recentGiftStatus?.alreadyReserved) {
      telemetry.alreadyReservedGiftViewed({ label: recentGiftStatus.usedEmail });
    }
  }, [telemetry, recentGiftStatus?.alreadyReserved, recentGiftStatus?.usedEmail]);

  return (
    <animated.div style={styleProps}>
      <TextV2 typographyVariant="hed3" marginBottom={1}>
        {product.brand}
      </TextV2>
      <StyledProductTitle typographyVariant="hed5" marginBottom={3}>
        {product.title}
      </StyledProductTitle>
      {checkIfIsPricedProduct(product) && (
        <>
          <Flex gap={2}>
            <TextV2 typographyVariant="hed4" color="mono13" textDecoration={!product.isFullPrice ? 'line-through' : ''}>
              {!product.isFullPrice ? fullPrice : product.wholeNumPrice}
            </TextV2>
            {!product?.isFullPrice && (
              <TextV2 typographyVariant="hed4" color="#D80B0B">
                {message()} {product.wholeNumPrice}
              </TextV2>
            )}
          </Flex>
          {hasToShowMsrp && <MsrpTooltip msrpDescription={msrpTranslations.msrpDescription} msrpTitle={msrpTranslations.msrpTitle} />}
        </>
      )}
      {product?.description && (
        <StyledProductDescriptionContainer marginTop={5}>
          <StyledDescriptionTitleContainer __css={styles.description(isDescriptionShowed)}>
            <TextV2 typographyVariant={'body2'}>{product.description}</TextV2>
          </StyledDescriptionTitleContainer>
          <ButtonV2
            variant="link"
            intent="neutral"
            typographyVariant="body1"
            lineHeight="tall"
            width="fit-content"
            color="mono14"
            onClick={() => setIsDescriptionShowed(!isDescriptionShowed)}
          >
            {isDescriptionShowed ? hideDescription() : readMore()}
          </ButtonV2>
        </StyledProductDescriptionContainer>
      )}
      {product.isGroupGiftingEnabled && product?.donationFund ? (
        <ProductInformationGroupGifting
          product={product}
          cannotPurchase={cannotPurchase}
          handleOnBuyNowClick={handleOnBuyNowClick}
          handleCheckoutDialogOpenCallback={closeDialog}
          isDropship
        />
      ) : (
        <Flex justifyContent="center" flexDirection="column">
          {recentGiftStatus?.alreadyReserved ? (
            <>
              <Divider marginY={6} />
              <Box>
                <TextV2 typographyVariant="hed4" paddingRight={2}>
                  {alreadyReservedTitle()}
                </TextV2>
                <TextV2 typographyVariant="body1" marginTop={7} paddingRight={2}>
                  {alreadyReservedSubtitle()}
                </TextV2>
                <TextV2 typographyVariant="body1" marginTop={7}>
                  {alreadyReservedCheckEmail({ email: recentGiftStatus.usedEmail })}
                </TextV2>
              </Box>
            </>
          ) : (
            <>
              {product?.requested > 1 && (
                <>
                  <Divider marginY={6} />
                  <StyledQuantityContainer __css={styles.quantityContainer}>
                    <StyledQuantityInfoWrapper __css={styles.quantityInfoWrapper}>
                      <TextV2 color="mono14" typographyVariant="hed1">
                        {quantityTitle()}
                      </TextV2>
                      <TextV2 color="mono10" typographyVariant="label2">
                        {needed({ StillNeeded: product.stillNeeded })}
                      </TextV2>
                    </StyledQuantityInfoWrapper>

                    {product?.requested > DROPDOWN_QUANTITY_LIMIT ? (
                      <ItemCounter
                        increaseCounter={() => {
                          formik.setFieldValue('quantity', formik.values.quantity + 1);
                        }}
                        disableIncrease={formik.values.quantity === product?.stillNeeded}
                        decreaseCounter={() => {
                          formik.setFieldValue('quantity', formik.values.quantity - 1);
                        }}
                        counter={formik.values.quantity}
                        disableRemoveOption={true}
                      />
                    ) : (
                      <StyledSelectWrapper __css={styles.selectWrapper}>
                        <SelectV1
                          defaultValue={stillNeededOptions[0]}
                          searchable={false}
                          options={stillNeededOptions}
                          fluid={false}
                          onChange={e => {
                            formik.setFieldValue('quantity', Number.parseInt(e?.value || '1'));
                          }}
                        />
                      </StyledSelectWrapper>
                    )}
                  </StyledQuantityContainer>
                </>
              )}

              {checkIfIsPricedProduct(product) && (
                <>
                  <Divider marginY={6} />
                  <StyledPriceSummaryContainer __css={styles.priceSummaryContainer}>
                    <Flex justifyContent="flex-end">
                      <TextV2 color="mono10" typographyVariant="body1">
                        {quantityTimesPrice({ quantity: formik.values.quantity, price: product.wholeNumPrice })}
                      </TextV2>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <TextV2 color="mono14" typographyVariant="hed1">
                        {subtotalTitle()}
                      </TextV2>
                      <TextV2 color="mono14" typographyVariant="hed1">
                        {totalPrice}
                      </TextV2>
                    </Flex>
                  </StyledPriceSummaryContainer>
                </>
              )}
              <StyledCTAContainer __css={styles.ctaContainer} marginTop={8}>
                {displayButtonAddToCart && (
                  <ButtonV2 type="button" intent="neutral" shape="rounded" disabled={cannotPurchase} variant="outline" onClick={handleOnAddToCartClick}>
                    {formButtonAddToCart()}
                  </ButtonV2>
                )}
                {cannotPurchase ? (
                  <ButtonV2 intent="neutral" shape="rounded" disabled={true}>
                    {outOfStock()}
                  </ButtonV2>
                ) : (
                  <LoadingExpressCheckoutElement
                    waitToLoad={expressCheckoutFeatureFlag.loading}
                    showFallback={expressCheckoutFeatureFlag.value !== 'on'}
                    onParentClick={event => {
                      telemetry.expressCheckoutClicked({ ...event, source: 'ProductInformation' });
                      history.push(router.buildPath(routePaths.checkout.goToPath(product.registryItemId, formik.values.quantity.toString())));
                    }}
                    disablePaymentEvents={true}
                    onConfirm={() => {}}
                    onReady={event => telemetry.expressCheckoutElementReady({ ...event, source: 'ProductInformation' })}
                    options={{ buttonHeight: 50, buttonType: { applePay: 'buy', googlePay: 'checkout' } }}
                  >
                    <ButtonV2 intent="neutral" shape="rounded" onClick={handleOnBuyNowClick}>
                      {formButtonBuyNow()}
                    </ButtonV2>
                  </LoadingExpressCheckoutElement>
                )}
                {purchaseErrorContent && <StyledPurchasableMessage typographyVariant="label2">{purchaseErrorContent}</StyledPurchasableMessage>}
                <ButtonV2 type="button" intent="neutral" variant="ghost" color="mono14" onClick={handleOnMarkAsPurchasedClick}>
                  {formButtonMarkAsPurchased()}
                </ButtonV2>
              </StyledCTAContainer>
            </>
          )}
        </Flex>
      )}
    </animated.div>
  );
};
