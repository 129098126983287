import { useEffect, useRef } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useCheckoutDialogContext } from '../../guest/components/CheckoutDialog';
import { useGetItemCheckoutMechanismsQuery, ShippingAddressInput, GetItemCheckoutMechanismsQuery, CheckoutMechanismTypeFragment, CheckoutMechanismType } from '@graphql/generated';

const JOY_ADDRESS: ShippingAddressInput = {
  name: 'Joy HQ',
  address1: '939 Harrison St',
  city: 'San Francisco',
  state: 'CA',
  postalCode: '94103',
  country: 'United States',
  validated: true,
  countryCode: 'USA'
};

type CheckoutMechanismsProps = Readonly<{
  productId: Readonly<string>;
}>;

export type CheckoutMechanismTypeValue = `${CheckoutMechanismType}`; // eg. 'joyWeb', 'externalNavigation'
type CheckoutMechanismResults = {
  dropshipMechanism: CheckoutMechanismTypeFragment | null;
  isDropshipable: boolean;
  isOutOfStock: boolean;
};
export const deriveCheckoutMechanismResults = (registryItem: GetItemCheckoutMechanismsQuery['registryItem'] | null | undefined): CheckoutMechanismResults => {
  const checkoutMechanismsWithFailureCodes = registryItem?.productData?.checkoutMechanisms ?? [];
  const checkoutMechanisms = checkoutMechanismsWithFailureCodes.filter(mechanism => !Boolean(mechanism.checkoutFailureCode)); // the happy ones
  const dropshipMechanism = checkoutMechanisms.find(mechanism => mechanism?.type === 'joyWeb') ?? null;
  const isDropshipable = Boolean(dropshipMechanism);
  const isOutOfStock = checkoutMechanisms.length === 0;

  return { dropshipMechanism, isDropshipable, isOutOfStock };
};

// CHECKOUT MECHANISMS FLOW
// -- First step --
// Checkout mechanisms query (called automatically)
// Variables: product id
// Result: checkoutMechanisms.type === 'joyWeb' ? isDropshipable = true && isOutOfStock = false. No more steps are needed

// -- Second step --
// Check shipping address from registry state
// Result: couple has address ? isDropshipable = false && isOutOfStock = true. No more steps are needed

// -- Third step --
// Refetch query (called through useEffect)
// Variables: product id and a hardcoded address (Joys' address)
// Result: checkoutMechanisms.type !== 'joyWeb' ? isDropshipable = false && isOutOfStock = checkoutMechanisms is empty []. No more steps are needed

// -- Fourth step --
// Refetch query (called through checkoutMechanismsWithAltAddress)
// Variables: product id and a shipping address entered by the guest
// Result: isDropshipable = checkoutMechanisms.type === 'joyWeb'

export const useCheckoutMechanisms = ({ productId }: CheckoutMechanismsProps) => {
  const isQueryWithJoyAddressAvailable = useRef(true);

  const { registryState } = useCheckoutDialogContext();
  const shippingAddress = registryState?.shippingAddress;

  const { data, loading, refetch, networkStatus } = useGetItemCheckoutMechanismsQuery({
    batchMode: 'fast',
    notifyOnNetworkStatusChange: true,
    variables: { id: productId }
  });
  const { dropshipMechanism, isDropshipable, isOutOfStock } = deriveCheckoutMechanismResults(data?.registryItem);

  const checkoutMechanismsWithAltAddress = async (altAddress: ShippingAddressInput): Promise<boolean> => {
    const queryResult = await refetch({ id: productId, shippingAddress: altAddress });
    return deriveCheckoutMechanismResults(queryResult?.data?.registryItem).isDropshipable;
  };

  const hideValueProps = dropshipMechanism?.hideValueProps ?? false;

  useEffect(() => {
    if (!!data && !isDropshipable && !shippingAddress && isQueryWithJoyAddressAvailable.current) {
      isQueryWithJoyAddressAvailable.current = false;
      refetch({ id: productId, shippingAddress: JOY_ADDRESS });
    }
  }, [data, isDropshipable, isQueryWithJoyAddressAvailable, productId, refetch, shippingAddress]);

  useEffect(() => {
    if (isQueryWithJoyAddressAvailable.current === false) {
      isQueryWithJoyAddressAvailable.current = true;
    }
  }, [productId]);

  return {
    isLoading: loading || networkStatus === NetworkStatus.fetchMore,
    isDropshipable,
    isOutOfStock,
    hideValueProps,
    checkoutMechanismsWithAltAddress
  };
};
