import { CookedProductList, isPricedProduct, ItemWithPrice } from '@apps/registry/common/selectors/ProductListSelector';
import { ECard, ShippingAddressFragment, ShippingAddressInput, useCreateRegistryPaymentIntentMutation, useGetCalculateRegistryPurchaseInfoQuery } from '@graphql/generated';
import { createContext } from '@shared/utils/createContext';
import { GuestRegistryState } from '@apps/registry/guest/GuestRegistry.controller';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GiftWrapOfferState } from '@apps/registry/guest/components/CheckoutDialog/components/GiftWrapOffer/GiftWrapOffer';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { prepareECardPayload, useGiftWrapECardPrice, useSaveCardDraft } from '@shared/components';
import { LineItem, StripeExpressCheckoutElementClickEvent, StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js';
import { GiftWrapType } from '../ShoppingCart/ShoppingCart.telemetry';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { useHistory, useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useFullPageLoader } from '@shared/components/JoyLogoLoader/FullPageLoaderProvider';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { ExpressCheckoutPurchaseItem } from './ExpressCheckout';

export interface MonetaryValue {
  valueInMinorUnits: number;
}

export enum ExpressCheckoutShippingOption {
  CoupleAddress = 'CoupleAddress',
  ShipToMe = 'ShipToMe'
}

export enum InternalCheckoutErrorCode {
  serverError = 'server_error',
  missingClientSecret = 'missing_client_secret',
  missingConfirmationToken = 'missing_confirmation_token',
  insufficientData = 'insufficient_data',
  unknownError = 'unknown_error',
  forbiddenShippingCountry = 'forbidden_shipping_country'
}

const countryAlpha2toAlpha3: Record<string, string> = {
  US: 'USA'
};

export interface CheckoutError {
  readonly code?: InternalCheckoutErrorCode | string;
  readonly message?: string;
}

export type ProductWithQuantity = ItemWithPrice & { quantity: number; totalValueInMinorUnits: number };

interface State {
  purchaseProducts: ProductWithQuantity[];
  productsPrice: MonetaryValue;
  totalPrice: MonetaryValue;
  shippingOption: ExpressCheckoutShippingOption;

  checkoutError?: CheckoutError | null;
  eCardPrice?: MonetaryValue | null;
  giftWrap?: GiftWrapOfferState | null;
  shippingCost?: MonetaryValue | null;
  coupleAddress?: ShippingAddressFragment | null;
}

interface Mutators {
  setGiftWrap: (giftWrap: GiftWrapOfferState) => void;
  setShippingOption: (option: ExpressCheckoutShippingOption) => void;
  handleCheckoutClick: (event: StripeExpressCheckoutElementClickEvent) => void;
  handleCheckoutConfirm: (event: StripeExpressCheckoutElementConfirmEvent) => void;
}

interface ExpressCheckoutProviderProps {
  eventHandle: string;
  eventId: string;
  purchaseItems: ExpressCheckoutPurchaseItem[];
  registryState: GuestRegistryState;
  allProducts: CookedProductList;
}

interface ExpressCheckoutContext {
  state: State;
  mutators: Mutators;
}

const RegionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

export const [Provider, useExpressCheckoutContext] = createContext<ExpressCheckoutContext>({ name: 'ExpressCheckout' });

export const ExpressCheckoutProvider: React.FC<ExpressCheckoutProviderProps> = ({ eventHandle, eventId, purchaseItems, registryState, allProducts, children }) => {
  const coupleAddress = registryState.shippingAddress;
  const elements = useElements();
  const telemetry = useGuestRegistryTelemetry();
  const stripe = useStripe();
  const routes = useRoutePaths();
  const history = useHistory();
  const [shippingOption, setShippingOption] = useState(coupleAddress ? ExpressCheckoutShippingOption.CoupleAddress : ExpressCheckoutShippingOption.ShipToMe);
  const [giftWrap, setGiftWrap] = useState<GiftWrapOfferState | null>(null);
  const [createRegistryPaymentIntentMutation] = useCreateRegistryPaymentIntentMutation();
  const { t2 } = useTranslation('sharedRegistry');
  const trans = t2('shoppingCart', 'shippingAddressStep');
  const giftWrapPrice = useGiftWrapECardPrice();
  const eCardPrice = useMemo(() => ({ valueInMinorUnits: giftWrapPrice * 100 }), [giftWrapPrice]);
  const [saveCardDraft] = useSaveCardDraft();
  const setFullPageLoading = useFullPageLoader({ key: 'ExpressCheckout', initialIsLoading: false });
  const [checkoutError, setCheckoutError] = useState<CheckoutError | null>(null);

  const purchaseProducts = useMemo(() => {
    const purchaseItemsMap = new Map(purchaseItems.map(item => [item.registryItemId, item]));
    return allProducts.products.reduce<ProductWithQuantity[]>((acc, product) => {
      const purchaseItem = purchaseItemsMap.get(product.registryItemId);
      if (purchaseItem && isPricedProduct(product)) {
        const requestedQuantity = purchaseItem.quantity ?? 1;
        const quantity = Math.min(product.stillNeeded, requestedQuantity < 1 ? 1 : requestedQuantity);
        acc.push({
          ...product,
          quantity,
          totalValueInMinorUnits: product.valueInMinorUnits * quantity
        });
      }
      return acc;
    }, []);
  }, [allProducts, purchaseItems]);

  const lineItems = useMemo(() => purchaseProducts?.map(product => ({ registryItemId: product.registryItemId, quantity: product.quantity })), [purchaseProducts]);

  const shippingAddress: ShippingAddressInput | null = coupleAddress ? (({ __typename, hash, ...rest }) => rest)(coupleAddress) : null;
  const { data: shippingData } = useGetCalculateRegistryPurchaseInfoQuery({
    variables: {
      payload: {
        eventId: eventId,
        address: shippingAddress,
        lineItems
      }
    },
    skip: !shippingAddress || !lineItems.length,
    batchMode: 'fast'
  });
  const shippingCost = shippingData?.calculateRegistryPurchaseInfo.shippingAndHandlingTotal;

  const productsPrice = useMemo<MonetaryValue>(() => ({ valueInMinorUnits: purchaseProducts.reduce((acc, product) => acc + product.totalValueInMinorUnits, 0) }), [
    purchaseProducts
  ]);

  const totalPrice = useMemo<MonetaryValue>(() => {
    let totalPriceInMinorUnits = productsPrice.valueInMinorUnits;
    if (shippingCost) {
      totalPriceInMinorUnits += shippingCost.valueInMinorUnits;
    }
    if (giftWrap?.wrapOption === 'eCard' && eCardPrice) {
      totalPriceInMinorUnits += eCardPrice.valueInMinorUnits;
    }
    return { valueInMinorUnits: totalPriceInMinorUnits };
  }, [productsPrice, shippingCost, giftWrap, eCardPrice]);

  useEffect(() => {
    if (!elements || !totalPrice.valueInMinorUnits) {
      return;
    }

    elements.update({
      amount: totalPrice.valueInMinorUnits,
      currency: registryState.registryCurrencyCode?.toLowerCase() ?? 'usd',
      mode: 'payment'
    });
  }, [elements, totalPrice, registryState]);

  const createECard = useCallback(
    async (giftWrap: GiftWrapOfferState): Promise<ECard | null> => {
      const product = purchaseProducts.at(0);
      if (!product) {
        return null;
      }

      let productLink;
      try {
        const origin = window?.location?.origin ?? 'https://withjoy.com';
        productLink = new URL(`${eventHandle}/edit/registry/track`, origin)?.href;
      } catch (e) {}

      const payload = {
        message: giftWrap.message,
        themeId: giftWrap.themeId,
        image: product.image,
        title: product.title,
        giftLink: productLink ?? ''
      };
      const draftId = await saveCardDraft(eventId, payload);

      if (typeof draftId === 'string') {
        return prepareECardPayload({
          price: eCardPrice.valueInMinorUnits / 100,
          draftId,
          theme: giftWrap.themeId,
          imageURL: product.image,
          offerType: GiftWrapType.CART
        });
      }
      return null;
    },
    [eCardPrice, purchaseProducts, saveCardDraft, eventHandle, eventId]
  );

  const handleCheckoutConfirm = useCallback(
    async (event: StripeExpressCheckoutElementConfirmEvent) => {
      const handleError = (error: CheckoutError) => {
        telemetry.checkoutError(error);
        setCheckoutError(error);
        event.paymentFailed({ reason: 'fail' });
      };
      if (!stripe || !elements || !giftWrap || !lineItems.length || !event.billingDetails?.email) {
        return handleError({ code: InternalCheckoutErrorCode.insufficientData });
      }

      if (event.shippingAddress && event.shippingAddress.address.country !== 'US') {
        return handleError({ code: InternalCheckoutErrorCode.forbiddenShippingCountry });
      }

      try {
        setFullPageLoading(true);

        let eCard: ECard | null = null;
        if (giftWrap.wrapOption === 'eCard') {
          eCard = await createECard(giftWrap);
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
          return handleError(submitError);
        }

        const { confirmationToken, error: tokenError } = await stripe.createConfirmationToken({ elements });
        if (tokenError) {
          return handleError(tokenError);
        }

        if (!confirmationToken.id) {
          return handleError({ code: InternalCheckoutErrorCode.missingConfirmationToken });
        }

        const returnUrlTemplate = `${withWindow(global => global.location.origin + routes.guestRegistry.path + '/purchase', '')}?pcId=\${purchaseContextId}`;
        let paymentIntentResult: Awaited<ReturnType<typeof createRegistryPaymentIntentMutation>>;
        try {
          paymentIntentResult = await createRegistryPaymentIntentMutation({
            variables: {
              payload: {
                eventId,
                name: event.shippingAddress?.name ?? event.billingDetails.name,
                email: event.billingDetails.email,
                note: giftWrap.message,
                lineItems,
                returnUrlTemplate,
                confirmationTokenId: confirmationToken.id,
                eCard,
                address:
                  event.shippingAddress && shippingOption === ExpressCheckoutShippingOption.ShipToMe
                    ? {
                        name: event.shippingAddress.name,
                        address1: event.shippingAddress.address.line1,
                        address2: event.shippingAddress.address.line2,
                        city: event.shippingAddress.address.city,
                        countryCode: countryAlpha2toAlpha3[event.shippingAddress.address.country],
                        country: RegionNamesInEnglish.of(event.shippingAddress.address.country) ?? event.shippingAddress.address.country,
                        postalCode: event.shippingAddress.address.postal_code,
                        state: event.shippingAddress.address.state,
                        phone: event.billingDetails.phone
                      }
                    : null
              }
            }
          });
        } catch (ex) {
          return handleError({ code: InternalCheckoutErrorCode.serverError });
        }

        const paymentIntent = paymentIntentResult.data?.createRegistryPaymentIntent;
        if (paymentIntent?.__typename !== 'RegistryPaymentIntent') {
          const code = paymentIntent && 'checkoutFailureCode' in paymentIntent ? paymentIntent.checkoutFailureCode : InternalCheckoutErrorCode.serverError;
          return handleError({ code });
        }

        if (paymentIntent.status === 'requires_action') {
          if (!paymentIntent.clientSecret) {
            return handleError({ code: InternalCheckoutErrorCode.missingClientSecret });
          }

          await stripe.handleNextAction({
            clientSecret: paymentIntent.clientSecret
          });
        }
        telemetry.checkoutSuccess({
          giftWrapOption: giftWrap.wrapOption,
          shippingOption,
          lineItems,
          productsPriceInMinorUnits: productsPrice.valueInMinorUnits,
          totalPriceInMinorUnits: totalPrice.valueInMinorUnits,
          expressPaymentType: event.expressPaymentType
        });
        const returnUrl = new URL(paymentIntent.returnUrl);
        history.replace(returnUrl.pathname + returnUrl.search);
      } catch (ex) {
        handleError({ code: InternalCheckoutErrorCode.unknownError });
      } finally {
        setFullPageLoading(false);
      }
    },
    [
      stripe,
      elements,
      giftWrap,
      shippingOption,
      routes,
      createECard,
      createRegistryPaymentIntentMutation,
      eventId,
      setFullPageLoading,
      history,
      setCheckoutError,
      telemetry,
      totalPrice,
      productsPrice,
      lineItems
    ]
  );

  const handleCheckoutClick = useCallback(
    (event: StripeExpressCheckoutElementClickEvent) => {
      telemetry.expressCheckoutClicked({ ...event, source: 'CheckoutPage' });
      const stripeLineItems: LineItem[] = purchaseProducts.map(product => ({ name: `${product.quantity} x ${product.title}`, amount: product.totalValueInMinorUnits }));
      if (giftWrap?.wrapOption === 'eCard') {
        stripeLineItems.push({ name: 'Personalized eCard', amount: eCardPrice.valueInMinorUnits });
      }
      if (shippingCost?.valueInMinorUnits) {
        stripeLineItems.push({ name: trans.shipping, amount: shippingCost.valueInMinorUnits });
      }
      event.resolve({
        allowedShippingCountries: ['US'],
        billingAddressRequired: true,
        emailRequired: true,
        lineItems: stripeLineItems,
        ...(shippingOption === ExpressCheckoutShippingOption.ShipToMe
          ? {
              shippingAddressRequired: true,
              phoneNumberRequired: true,
              shippingRates: [
                {
                  id: 'default',
                  displayName: shippingCost?.valueInMinorUnits === 0 ? trans.freeShipping : trans.standardShipping,
                  amount: shippingCost?.valueInMinorUnits ?? 0
                }
              ]
            }
          : {})
      });
    },
    [shippingOption, purchaseProducts, trans, shippingCost, giftWrap, eCardPrice, telemetry]
  );

  return (
    <Provider
      value={{
        state: {
          purchaseProducts,
          giftWrap,
          shippingCost,
          shippingOption,
          coupleAddress,
          productsPrice,
          totalPrice,
          eCardPrice,
          checkoutError
        },
        mutators: {
          setShippingOption,
          setGiftWrap,
          handleCheckoutClick,
          handleCheckoutConfirm
        }
      }}
    >
      {children}
    </Provider>
  );
};
