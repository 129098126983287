import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { SalesOrder } from './JoyPurchasedOrder.types';

export function calcStripeFee(salesOrder: SalesOrder, hasGiftWrapCard: boolean, giftWrapPrice: number) {
  const total = salesOrder?.total?.valueInMinorUnits ? salesOrder?.total?.valueInMinorUnits / 100 : 0;
  const subTotal = calcSubtotal(salesOrder);
  const taxesTotal = salesOrder?.taxesTotal?.valueInMinorUnits ? salesOrder?.taxesTotal?.valueInMinorUnits / 100 : 0;
  const shippingAndHandlingTotal = salesOrder?.shippingAndHandlingTotal?.valueInMinorUnits ? salesOrder?.shippingAndHandlingTotal?.valueInMinorUnits / 100 : 0;
  const giftwrap = hasGiftWrapCard ? giftWrapPrice : 0;

  return total - (subTotal + taxesTotal + shippingAndHandlingTotal + giftwrap);
}

export function calcSubtotal(salesOrder: SalesOrder) {
  // FIXME: shipWhatWeCan
  //   Compensation should be treated as Joy Credit, vs. mocked as a "real Item"
  const subtotal = (salesOrder?.subtotal?.valueInMinorUnits ?? 0) / 100;
  const compensationTotal = (salesOrder?.compensationTotal?.valueInMinorUnits ?? 0) / 100;

  return subtotal + compensationTotal;
}

export function deriveCurrencyCode(salesOrder: SalesOrder) {
  // this is obtuse
  return salesOrder?.subtotal?.currency?.code || DEFAULT_CURRENCY;
}
